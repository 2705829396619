import { toast } from "@/hooks/use-toast";

// Default backend URL for production
const DEFAULT_BACKEND_URL = 'https://api.proposal-stream.com';

// Get backend URL from environment or default
const getBackendUrl = () => {
  const backendUrl = import.meta.env.VITE_BACKEND_URL || DEFAULT_BACKEND_URL;

  console.log('Backend URL:', {
    url: backendUrl,
    env: import.meta.env.VITE_BACKEND_URL,
    mode: import.meta.env.MODE,
    timestamp: new Date().toISOString()
  });

  return backendUrl;
};

export class FetchError extends Error {
  info: any;
  status: number;
  constructor(message: string, info: any, status: number) {
    super(message);
    this.info = info;
    this.status = status;
  }
}

// Fetcher function for SWR that includes credentials and handles non-200 responses
export const fetcher = async (url: string, init?: RequestInit) => {
  const backendUrl = getBackendUrl();
  const fullUrl = url.startsWith('http') ? url : `${backendUrl}${url}`;

  console.log('Fetching:', {
    url: fullUrl,
    backendUrl,
    mode: import.meta.env.MODE,
    headers: init?.headers,
    cookies: document.cookie,
    timestamp: new Date().toISOString()
  });

  try {
    const response = await fetch(fullUrl, {
      ...init,
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Origin': window.location.origin,
        'X-Requested-With': 'XMLHttpRequest',
        ...init?.headers,
      }
    });

    console.log('Fetch response:', {
      url: fullUrl,
      status: response.status,
      headers: Object.fromEntries(response.headers.entries()),
      timestamp: new Date().toISOString()
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ message: 'An error occurred' }));
      console.log('Fetch error:', {
        url: fullUrl,
        error: errorData.message || 'Unauthorized',
        timestamp: new Date().toISOString()
      });
      if (response.status === 401) {
        // Redirect to login for unauthorized requests
        window.location.href = '/login';
        throw new FetchError('Unauthorized', { message: 'Please log in' }, response.status);
      }
      throw new FetchError(
        errorData.message || 'An error occurred',
        errorData,
        response.status
      );
    }

    const data = await response.json();
    return data;
  } catch (error: any) {
    console.error('Fetch error:', {
      url: fullUrl,
      error: error.message,
      timestamp: new Date().toISOString()
    });
    throw error;
  }
};
