import { useState, useEffect, useRef, useCallback } from "react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { useLocation } from "wouter";
import * as emailjs from '@emailjs/browser';

interface GoogleCredentialResponse {
  credential: string;
}

interface GoogleSignInButtonProps {
  onError: (error: Error) => void;
  onSuccess?: (response: GoogleCredentialResponse) => void;
}

interface GoogleSignInFallbackProps {
  error: Error;
  onRetry: () => void;
}

const RETRY_DELAYS = [1000, 2000, 4000, 8000];

declare global {
  interface Window {
    google?: {
      accounts: {
        id: {
          initialize: (config: any) => void;
          renderButton: (element: HTMLElement, config: any) => void;
          prompt: () => void;
        };
      };
    };
  }
}

export function GoogleSignInButton({ onError, onSuccess }: GoogleSignInButtonProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [isGoogleInitialized, setIsGoogleInitialized] = useState(false);
  const [initializationAttempt, setInitializationAttempt] = useState(0);
  const [initError, setInitError] = useState<Error | null>(null);
  const { toast } = useToast();
  const [, navigate] = useLocation();
  const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
  const buttonRef = useRef<HTMLDivElement>(null);

  const handleCredentialResponse = async (response: GoogleCredentialResponse) => {
    try {
      // Decode the JWT to get user information
      const base64Url = response.credential.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      
      const { email, name } = JSON.parse(jsonPayload);

      // First try to authenticate
      if (onSuccess) {
        onSuccess(response);
        return;
      }

      // If no onSuccess handler (not in auth flow), proceed with waitlist flow
      const backendUrl = import.meta.env.VITE_BACKEND_URL || 'https://api.proposal-stream.com';
      const waitlistResponse = await fetch(`${backendUrl}/api/waitlist`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          name,
          credential: response.credential
        }),
      });

      if (!waitlistResponse.ok) {
        const errorData = await waitlistResponse.json();
        if (errorData.message === 'Email already registered') {
          toast({
            title: "Already Registered",
            description: "You're already on our waitlist. We'll contact you soon!",
          });
          navigate('/waitlist-thank-you');
          return;
        }
        throw new Error('Failed to join waitlist');
      }

      // Send confirmation email
      await emailjs.send(
        import.meta.env.VITE_EMAILJS_SERVICE_ID,
        import.meta.env.VITE_EMAILJS_TEMPLATE_ID,
        {
          to_email: email,
          to_name: name,
        },
        import.meta.env.VITE_EMAILJS_PUBLIC_KEY
      );

      toast({
        title: "Success!",
        description: "You've been added to our waitlist.",
      });

      navigate('/waitlist-thank-you');
    } catch (error) {
      console.error('Google Sign-in error:', error);
      onError(error instanceof Error ? error : new Error('Failed to process sign-in'));
      toast({
        title: "Error",
        description: "Failed to process sign-in. Please try again.",
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    // Load the Google Sign-In script
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.google) {
        try {
          window.google.accounts.id.initialize({
            client_id: clientId,
            callback: handleCredentialResponse,
            auto_select: false,
            cancel_on_tap_outside: true,
            itp_support: true,
            context: 'signin',
            ux_mode: 'popup',
          });

          if (buttonRef.current) {
            window.google.accounts.id.renderButton(buttonRef.current, {
              theme: 'outline',
              size: 'large',
              type: 'standard',
              text: 'signin_with',
              shape: 'rectangular',
              logo_alignment: 'left',
              width: buttonRef.current.offsetWidth,
            });
          }
          setIsGoogleInitialized(true);
        } catch (error) {
          console.error('Google Sign-In initialization error:', error);
          setInitError(error instanceof Error ? error : new Error('Failed to initialize Google Sign-In'));
        }
      }
    };

    script.onerror = () => {
      const error = new Error('Failed to load Google Sign-In script');
      console.error(error);
      setInitError(error);
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [clientId, handleCredentialResponse]);

  if (initError) {
    return (
      <div className="flex justify-center">
        <GoogleSignInFallback 
          error={initError} 
          onRetry={() => {
            setInitError(null);
            setInitializationAttempt(prev => prev + 1);
            setIsLoading(true);
            const script = document.createElement('script');
            script.src = 'https://accounts.google.com/gsi/client';
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);
            script.onload = () => {
              if (window.google) {
                try {
                  window.google.accounts.id.initialize({
                    client_id: clientId,
                    callback: handleCredentialResponse,
                    auto_select: false,
                    cancel_on_tap_outside: true,
                    itp_support: true,
                    context: 'signin',
                    ux_mode: 'popup',
                  });

                  if (buttonRef.current) {
                    window.google.accounts.id.renderButton(buttonRef.current, {
                      theme: 'outline',
                      size: 'large',
                      type: 'standard',
                      text: 'signin_with',
                      shape: 'rectangular',
                      logo_alignment: 'left',
                      width: buttonRef.current.offsetWidth,
                    });
                  }
                  setIsGoogleInitialized(true);
                } catch (error) {
                  console.error('Google Sign-In initialization error:', error);
                  setInitError(error instanceof Error ? error : new Error('Failed to initialize Google Sign-In'));
                }
              }
            };
          }} 
        />
      </div>
    );
  }

  return (
    <div 
      ref={buttonRef} 
      className="w-full"
      style={{
        minHeight: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {isLoading ? (
        <Button 
          variant="outline" 
          className="w-full h-10 flex items-center justify-center gap-2"
          disabled
        >
          <svg className="w-5 h-5" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
            />
            <path
              fill="currentColor"
              d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
            />
            <path
              fill="currentColor"
              d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
            />
            <path
              fill="currentColor"
              d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
            />
          </svg>
          Loading Google Sign-In...
        </Button>
      ) : (
        <div />
      )}
    </div>
  );
}

export function GoogleSignInFallback({ error, onRetry }: GoogleSignInFallbackProps) {
  return (
    <div className="text-center">
      <Button onClick={onRetry} variant="outline" className="w-[280px]">
        Retry Google Sign-In
      </Button>
      <p className="mt-2 text-sm text-destructive">{error.message}</p>
    </div>
  );
}
