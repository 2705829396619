// client/src/components/ServiceSelection/ServiceSelectionStep.tsx
import { useState } from "react";
import { Checkbox } from "@/components/ui/checkbox";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Card } from "@/components/ui/card";
import { ArrowRight } from "lucide-react";

interface ServiceSelectionStepProps {
  selectedServices: string[];
  onUpdate: (services: string[]) => void;
  onNext: () => void;
}

const AVAILABLE_SERVICES = [
  {
    id: "project-management",
    name: "Project Management Software",
    description: "Streamline project tracking, scheduling, and team collaboration.",
  },
  {
    id: "estimating",
    name: "Estimating & Bidding",
    description: "Automate and improve your estimation process with modern tools.",
  },
  {
    id: "field-operations",
    name: "Field Operations",
    description: "Mobile solutions for field teams, time tracking, and documentation.",
  },
  {
    id: "accounting-erp",
    name: "Accounting & ERP",
    description: "Integration and optimization of financial and resource planning systems.",
  },
  {
    id: "crm-sales",
    name: "CRM & Sales Pipeline",
    description: "Improve client relationships and sales process management.",
  },
  {
    id: "custom-automation",
    name: "Custom Automation",
    description: "Tailored solutions for your specific workflow challenges.",
  },
];

export function ServiceSelectionStep({
  selectedServices,
  onUpdate,
  onNext,
}: ServiceSelectionStepProps) {
  const [selectionType, setSelectionType] = useState<"audit" | "specific">("audit");

  const handleToggleService = (serviceId: string) => {
    if (selectedServices.includes(serviceId)) {
      onUpdate(selectedServices.filter((id) => id !== serviceId));
    } else {
      onUpdate([...selectedServices, serviceId]);
    }
  };

  return (
    <div className="space-y-6">
      <div className="text-center">
        <h2 className="text-xl sm:text-2xl font-bold mb-3">How Can We Help?</h2>
      </div>

      <RadioGroup
        value={selectionType}
        onValueChange={(value: "audit" | "specific") => {
          setSelectionType(value);
          onUpdate([]);
        }}
        className="grid grid-cols-1 sm:grid-cols-2 gap-4"
      >
        <div className="relative">
          <RadioGroupItem
            value="audit"
            id="audit"
            className="peer sr-only"
          />
          <Label
            htmlFor="audit"
            className="flex flex-col gap-2 p-4 rounded-lg border-2 cursor-pointer peer-data-[state=checked]:border-primary hover:border-primary/50 transition-colors"
          >
            <div className="font-semibold">Free Technology Audit</div>
            <p className="text-sm text-muted-foreground">
              Let us analyze your current tech stack and recommend the best solutions for your business.
              No commitment required.
            </p>
          </Label>
        </div>

        <div className="relative">
          <RadioGroupItem
            value="specific"
            id="specific"
            className="peer sr-only"
          />
          <Label
            htmlFor="specific"
            className="flex flex-col gap-2 p-4 rounded-lg border-2 cursor-pointer peer-data-[state=checked]:border-primary hover:border-primary/50 transition-colors"
          >
            <div className="font-semibold">Select Specific Services</div>
            <p className="text-sm text-muted-foreground">
              Know what you need? Choose from our range of services to get started right away.
            </p>
          </Label>
        </div>
      </RadioGroup>

      {selectionType === "specific" && (
        <div className="space-y-4 animate-in fade-in slide-in-from-top-4">
          <div className="grid gap-3">
            {AVAILABLE_SERVICES.map((service) => (
              <div
                key={service.id}
                className="flex items-start space-x-3 p-3 rounded-lg border border-border/50 hover:border-primary/50 transition-colors"
              >
                <Checkbox
                  id={service.id}
                  checked={selectedServices.includes(service.id)}
                  onCheckedChange={() => handleToggleService(service.id)}
                  className="mt-0.5"
                />
                <div className="space-y-0.5 min-w-0">
                  <Label
                    htmlFor={service.id}
                    className="text-sm font-medium cursor-pointer block truncate"
                  >
                    {service.name}
                  </Label>
                  <p className="text-xs text-muted-foreground">
                    {service.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="flex justify-end pt-4">
        <Button
          onClick={onNext}
          disabled={selectionType === "specific" && selectedServices.length === 0}
          className="w-full sm:w-auto text-sm sm:text-base px-4 sm:px-6 py-2 sm:py-2.5 h-auto"
        >
          Continue
          <ArrowRight className="ml-2 h-4 w-4 sm:h-5 sm:w-5" />
        </Button>
      </div>
    </div>
  );
}