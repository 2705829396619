// client/src/components/ServiceSelection/ContactDetailsStep.tsx
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { ArrowLeft, ArrowRight } from "lucide-react";

interface ContactDetails {
  email: string;
  name: string;
  companyName: string;
  phoneNumber?: string;
  city?: string;
  state?: string;
  country: string;
  otherCountry?: string;
  companySize?: string;
}

interface ContactDetailsStepProps {
  contactDetails: ContactDetails;
  selectedServices: string[];
  onUpdate: (details: ContactDetails) => void;
  onNext: () => void;
  onBack: () => void;
}

const US_STATES = [
  "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA",
  "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD",
  "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ",
  "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC",
  "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
];

const COUNTRIES = ["United States", "Canada", "Other"];
const COMPANY_SIZES = [
  "1-10 employees", "11-25 employees", "26-50 employees",
  "51-100 employees", "101-250 employees", "251-500 employees",
  "501-1000 employees", "1000+ employees"
];

export function ContactDetailsStep({
  contactDetails,
  selectedServices,
  onUpdate,
  onNext,
  onBack,
}: ContactDetailsStepProps) {
  const isValid = contactDetails.email && contactDetails.name && contactDetails.companyName;

  const handleNext = () => {
    if (!isValid) return;
    onNext();
  };

  return (
    <div className="space-y-6">
      <div className="text-center">
        <h2 className="text-2xl font-bold mb-2">Your Contact Information</h2>
        <p className="text-muted-foreground">
          Help us understand your business better to provide an accurate quote.
        </p>
      </div>

      <div className="grid gap-4">
        <div>
          <Label htmlFor="email">Email <span className="text-destructive">*</span></Label>
          <Input
            id="email"
            type="email"
            required
            value={contactDetails.email}
            onChange={(e) => onUpdate({ ...contactDetails, email: e.target.value })}
          />
        </div>

        <div>
          <Label htmlFor="name">Full Name <span className="text-destructive">*</span></Label>
          <Input
            id="name"
            required
            value={contactDetails.name}
            onChange={(e) => onUpdate({ ...contactDetails, name: e.target.value })}
          />
        </div>

        <div>
          <Label htmlFor="companyName">Company Name <span className="text-destructive">*</span></Label>
          <Input
            id="companyName"
            required
            value={contactDetails.companyName}
            onChange={(e) => onUpdate({ ...contactDetails, companyName: e.target.value })}
          />
        </div>

        <div>
          <Label htmlFor="phoneNumber">Phone Number</Label>
          <Input
            id="phoneNumber"
            type="tel"
            value={contactDetails.phoneNumber}
            onChange={(e) => onUpdate({ ...contactDetails, phoneNumber: e.target.value })}
          />
        </div>

        <div>
          <Label htmlFor="country">Country <span className="text-destructive">*</span></Label>
          <Select
            value={contactDetails.country}
            onValueChange={(value) => onUpdate({ ...contactDetails, country: value })}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select country" />
            </SelectTrigger>
            <SelectContent>
              {COUNTRIES.map((country) => (
                <SelectItem key={country} value={country}>{country}</SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        {contactDetails.country === "United States" && (
          <div className="grid grid-cols-2 gap-4">
            <div>
              <Label htmlFor="city">City</Label>
              <Input
                id="city"
                value={contactDetails.city}
                onChange={(e) => onUpdate({ ...contactDetails, city: e.target.value })}
              />
            </div>
            <div>
              <Label htmlFor="state">State</Label>
              <Select
                value={contactDetails.state}
                onValueChange={(value) => onUpdate({ ...contactDetails, state: value })}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select state" />
                </SelectTrigger>
                <SelectContent>
                  {US_STATES.map((state) => (
                    <SelectItem key={state} value={state}>{state}</SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
        )}

        <div>
          <Label htmlFor="companySize">Company Size</Label>
          <Select
            value={contactDetails.companySize}
            onValueChange={(value) => onUpdate({ ...contactDetails, companySize: value })}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select company size" />
            </SelectTrigger>
            <SelectContent>
              {COMPANY_SIZES.map((size) => (
                <SelectItem key={size} value={size}>{size}</SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>

      <div className="flex justify-between mt-6">
        <Button variant="outline" onClick={onBack}>
          <ArrowLeft className="mr-2 h-4 w-4" /> Back
        </Button>
        <Button onClick={handleNext} disabled={!isValid}>
          Continue <ArrowRight className="ml-2 h-4 w-4" />
        </Button>
      </div>
    </div>
  );
}