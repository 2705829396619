// /Users/guyma/code/PS-pMVP/client/src/pages/FutureProjects.tsx
import { Header } from "@/components/Header";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useLocation } from "wouter";
import { ArrowRight, Check } from "lucide-react";
import { Pricing } from "@/components/Pricing";

export default function FutureProjects() {
  const [, navigate] = useLocation();

  return (
    <div className="min-h-screen bg-background">
      <Header />
      
      {/* Hero Section */}
      <section className="pt-12 pb-8 sm:pt-16 sm:pb-12">
        <div className="container mx-auto px-3 sm:px-8">
          <div className="max-w-2xl mx-auto text-center">
            <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-3 sm:mb-4">
              The Future of Property Management
            </h1>
            <p className="text-base sm:text-lg text-muted-foreground">
              We're building the next generation of tools to revolutionize vendor-property manager communication
            </p>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="pb-8 sm:pb-12">
        <div className="container mx-auto px-3 sm:px-8">
          <div className="max-w-3xl mx-auto">
            <Card className="overflow-hidden bg-card/80 backdrop-blur-sm">
              <div className="p-4 sm:p-6 space-y-6">
                <div>
                  <h2 className="text-xl sm:text-2xl font-semibold mb-2">Proposal Management System</h2>
                  <p className="text-sm sm:text-base text-muted-foreground">
                    A comprehensive solution for streamlining the entire proposal lifecycle
                  </p>
                </div>

                <div className="grid sm:grid-cols-2 gap-4">
                  <div className="flex items-start gap-3">
                    <div className="mt-1 h-5 w-5 rounded-full bg-primary/10 flex items-center justify-center flex-shrink-0">
                      <Check className="h-3 w-3 text-primary" />
                    </div>
                    <div>
                      <h3 className="text-sm font-medium">Quote Collection</h3>
                      <p className="text-sm text-muted-foreground">Automated processing and organization</p>
                    </div>
                  </div>
                  <div className="flex items-start gap-3">
                    <div className="mt-1 h-5 w-5 rounded-full bg-primary/10 flex items-center justify-center flex-shrink-0">
                      <Check className="h-3 w-3 text-primary" />
                    </div>
                    <div>
                      <h3 className="text-sm font-medium">Smart Generation</h3>
                      <p className="text-sm text-muted-foreground">AI-powered proposal creation</p>
                    </div>
                  </div>
                  <div className="flex items-start gap-3">
                    <div className="mt-1 h-5 w-5 rounded-full bg-primary/10 flex items-center justify-center flex-shrink-0">
                      <Check className="h-3 w-3 text-primary" />
                    </div>
                    <div>
                      <h3 className="text-sm font-medium">Real-time Collaboration</h3>
                      <p className="text-sm text-muted-foreground">Work together seamlessly</p>
                    </div>
                  </div>
                  <div className="flex items-start gap-3">
                    <div className="mt-1 h-5 w-5 rounded-full bg-primary/10 flex items-center justify-center flex-shrink-0">
                      <Check className="h-3 w-3 text-primary" />
                    </div>
                    <div>
                      <h3 className="text-sm font-medium">Digital Workflow</h3>
                      <p className="text-sm text-muted-foreground">Signatures and approvals made easy</p>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section className="py-8 sm:py-12 bg-gradient-to-b from-background via-secondary/20 to-background">
        <div className="container mx-auto px-3 sm:px-8">
          <Pricing />
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-12 sm:py-16">
        <div className="container mx-auto px-3 sm:px-8">
          <div className="max-w-xl mx-auto text-center">
            <h2 className="text-xl sm:text-2xl font-semibold mb-3">Ready to Transform Your Business?</h2>
            <p className="text-sm sm:text-base text-muted-foreground mb-6">
              Join our waitlist to be the first to know when these features become available
            </p>
            <Button 
              size="lg" 
              onClick={() => navigate('/contact')} 
              className="w-full sm:w-auto text-sm sm:text-base px-6 sm:px-8 py-3 sm:py-4"
            >
              Join the Waitlist
              <ArrowRight className="ml-2 h-4 w-4" />
            </Button>
          </div>
        </div>
      </section>
    </div>
  );
}