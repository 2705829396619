import Plyr from 'plyr-react';
import 'plyr-react/plyr.css';
import { useState, useEffect, useRef } from 'react';

interface VideoPlayerProps {
  src: string;
  poster?: string;
  title: string;
  description?: string;
}

export function VideoPlayer({ src, poster, title, description }: VideoPlayerProps) {
  const [showAudioPrompt, setShowAudioPrompt] = useState(true);
  const playerRef = useRef<Plyr | null>(null);

  const videoOptions = {
    controls: [
      'play-large',
      'play',
      'progress',
      'current-time',
      'mute',
      'volume',
      'fullscreen'
    ],
    muted: true,
    autoplay: false,
    settings: ['quality', 'speed'],
    quality: { default: 1080, options: [1080, 720, 480] }
  };

  const videoSource = {
    type: 'video',
    sources: [
      {
        src,
        type: 'video/mp4',
      }
    ],
    poster
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAudioPrompt(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const handleUnmute = () => {
    if (playerRef.current) {
      playerRef.current.muted = false;
      setShowAudioPrompt(false);
    }
  };

  return (
    <div className="relative w-full max-w-4xl mx-auto">
      <div className="relative">
        {showAudioPrompt && (
          <div className="absolute top-4 left-1/2 transform -translate-x-1/2 z-10 bg-background/90 backdrop-blur-sm px-4 py-2 rounded-full shadow-lg border border-border/50">
            <button
              onClick={handleUnmute}
              className="text-sm text-foreground/80 hover:text-foreground flex items-center gap-2 transition-colors"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5" />
                <path d="M15.54 8.46a5 5 0 0 1 0 7.07" />
                <path d="M19.07 4.93a10 10 0 0 1 0 14.14" />
              </svg>
              Turn on audio
            </button>
          </div>
        )}
        <div className="aspect-video rounded-lg overflow-hidden shadow-lg">
          <Plyr
            options={videoOptions}
            source={videoSource}
            ref={(plyr) => {
              if (plyr?.plyr) {
                playerRef.current = plyr.plyr;
              }
            }}
          />
        </div>
      </div>
      {(title || description) && (
        <div className="mt-4 text-center">
          <h3 className="text-lg font-semibold text-foreground">{title}</h3>
          {description && (
            <p className="mt-2 text-sm text-muted-foreground">{description}</p>
          )}
        </div>
      )}
    </div>
  );
}