// client/src/components/ServiceSelection/MessageStep.tsx
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { ArrowLeft, ArrowRight } from "lucide-react";

interface MessageStepProps {
  message: string;
  onUpdate: (message: string) => void;
  onNext: () => void;
  onBack: () => void;
}

export function MessageStep({
  message,
  onUpdate,
  onNext,
  onBack,
}: MessageStepProps) {
  return (
    <div className="space-y-4 sm:space-y-6">
      <div className="text-center">
        <h2 className="text-xl sm:text-2xl font-bold mb-1.5 sm:mb-2">Tell Us About Your Needs</h2>
        <p className="text-sm sm:text-base text-muted-foreground max-w-[90%] mx-auto">
          Help us understand your current challenges and goals. The more details you provide, the better we can tailor our solutions.
        </p>
      </div>

      <Textarea
        value={message}
        onChange={(e) => onUpdate(e.target.value)}
        placeholder="For example:
- What software are you currently using?
- What are your biggest pain points?
- Are there specific features you're looking for?
- What's your timeline for implementation?"
        className="min-h-[160px] text-sm sm:text-base"
      />

      <div className="flex justify-between items-center pt-2 sm:pt-4">
        <Button
          variant="outline"
          onClick={onBack}
          className="text-sm sm:text-base px-4 sm:px-6 py-2 sm:py-2.5 h-auto"
        >
          Back
        </Button>
        <Button
          onClick={onNext}
          disabled={!message.trim()}
          className="text-sm sm:text-base px-4 sm:px-6 py-2 sm:py-2.5 h-auto"
        >
          Continue
        </Button>
      </div>
    </div>
  );
}