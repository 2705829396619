import useSWR, { mutate } from "swr";
import type { PricingSheet } from "../../../server/db/schema";
import { fetcher } from "@/lib/fetcher";

interface PricingSheetResponse {
  status: "success" | "error";
  data: PricingSheet[];
  message?: string;
}

interface UploadResponse {
  status: "success" | "error";
  data?: PricingSheet;
  message?: string;
}

export function usePricingSheets() {
  const { data: response, error, isLoading, mutate: mutateSheets } = useSWR<PricingSheetResponse>(
    "/api/pricing-sheets",
    fetcher,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: true,
      errorRetryCount: 3
    }
  );

  return {
    sheets: response?.data || [],
    isLoading,
    error,
    async upload(file: File, name: string) {
      console.log('Starting pricing sheet upload:', { 
        name, 
        fileType: file.type, 
        fileSize: file.size,
        timestamp: new Date().toISOString()
      });

      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", name);

      try {
        const backendUrl = import.meta.env.VITE_BACKEND_URL || 'https://api.proposal-stream.com';
        const response = await fetch(`${backendUrl}/api/pricing-sheets`, {
          method: "POST",
          credentials: 'include',
          headers: {
            'Accept': 'application/json',
            'Origin': window.location.origin,
            'X-Requested-With': 'XMLHttpRequest'
          },
          body: formData,
        });

        const responseData: UploadResponse = await response.json();
        console.log('Upload response:', {
          ...responseData,
          timestamp: new Date().toISOString()
        });

        if (!response.ok || responseData.status === "error") {
          throw new Error(responseData.message || "Failed to upload pricing sheet");
        }

        // Immediately refresh the pricing sheets list
        await mutateSheets((currentData) => {
          if (!currentData || !responseData.data) return currentData;
          return {
            ...currentData,
            data: [responseData.data, ...currentData.data]
          };
        }, false);
        
        // Then revalidate from server
        await mutateSheets();
        
        return responseData;
      } catch (error: any) {
        console.error('Error uploading pricing sheet:', {
          error,
          errorMessage: error.message,
          name,
          fileType: file.type,
          fileSize: file.size,
          statusCode: error.status,
          timestamp: new Date().toISOString()
        });
        throw error;
      }
    },
    async deleteSheet(id: number) {
      try {
        const backendUrl = import.meta.env.VITE_BACKEND_URL || 'ttps://api.proposal-stream.com';
        const response = await fetch(`${backendUrl}/api/pricing-sheets/${id}`, {
          method: "DELETE",
          credentials: 'include',
          headers: {
            'Accept': 'application/json',
            'Origin': window.location.origin,
            'X-Requested-With': 'XMLHttpRequest'
          }
        });

        if (!response.ok) {
          const error = await response.json();
          throw new Error(error.message || "Failed to delete pricing sheet");
        }

        // Optimistically update the UI
        await mutateSheets((currentData) => {
          if (!currentData) return currentData;
          return {
            ...currentData,
            data: currentData.data.filter(sheet => sheet.id !== id)
          };
        }, false);

        // Then revalidate from server
        await mutateSheets();
      } catch (error: any) {
        console.error('Error deleting pricing sheet:', {
          error,
          errorMessage: error.message,
          id,
          statusCode: error.status,
          timestamp: new Date().toISOString()
        });
        throw error;
      }
    },
    async renameSheet(id: number, name: string) {
      try {
        const backendUrl = import.meta.env.VITE_BACKEND_URL || 'https://api.proposal-stream.com';
        const response = await fetch(`${backendUrl}/api/pricing-sheets/${id}`, {
          method: "PUT",
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Origin': window.location.origin,
            'X-Requested-With': 'XMLHttpRequest'
          },
          body: JSON.stringify({ name })
        });

        if (!response.ok) {
          const error = await response.json();
          throw new Error(error.message || "Failed to rename pricing sheet");
        }

        const responseData = await response.json();

        // Optimistically update the UI
        await mutateSheets((currentData) => {
          if (!currentData) return currentData;
          return {
            ...currentData,
            data: currentData.data.map(sheet => 
              sheet.id === id ? { ...sheet, name } : sheet
            )
          };
        }, false);

        // Then revalidate from server
        await mutateSheets();

        return responseData;
      } catch (error: any) {
        console.error('Error renaming pricing sheet:', {
          error,
          errorMessage: error.message,
          id,
          name,
          statusCode: error.status,
          timestamp: new Date().toISOString()
        });
        throw error;
      }
    }
  };
}