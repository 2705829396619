import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Check } from "lucide-react";
import { useState } from "react";

export function Pricing() {
  const [isAnnual, setIsAnnual] = useState(false);

  const plans = {
    starter: {
      monthly: 'TBD',
      annual: 'TBD' 
    },
    professional: {
      monthly: 'TBD',
      annual: 'TBD' 
    }
  };

  const getPrice = (plan: keyof typeof plans) => {
    return isAnnual ? plans[plan].annual : plans[plan].monthly;
  };

  const getAnnualSavings = (plan: keyof typeof plans) => {
    if (plan === 'professional') return '';
    const monthly = plans[plan].monthly;
    const annual = plans[plan].annual;
    return (monthly * 12) - (annual * 12);
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="text-center mb-6 sm:mb-8">
        <h2 className="text-xl sm:text-2xl font-semibold mb-2 sm:mb-3">Simple, Transparent Pricing</h2>
        <p className="text-sm sm:text-base text-muted-foreground">
          Choose the plan that's right for your business
        </p>
      </div>

      <div className="flex items-center justify-center gap-3 mb-6 sm:mb-8">
        <span className={`text-sm ${!isAnnual ? 'text-foreground' : 'text-muted-foreground'}`}>Monthly</span>
        <button
          onClick={() => setIsAnnual(!isAnnual)}
          className="relative w-11 h-6 rounded-full bg-primary/10 transition-colors duration-200"
        >
          <div className={`absolute top-1 h-4 w-4 rounded-full bg-primary transition-transform duration-200 ${isAnnual ? 'translate-x-6' : 'translate-x-1'}`} />
        </button>
        <span className={`text-sm ${isAnnual ? 'text-foreground' : 'text-muted-foreground'}`}>
          Annual <span className="text-xs text-primary">(Save ${getAnnualSavings('starter')})</span>
        </span>
      </div>

      <div className="grid sm:grid-cols-2 gap-4">
        {/* Starter Plan */}
        <Card className="relative overflow-hidden bg-card/80 backdrop-blur-sm">
          <div className="p-4 sm:p-6 space-y-4 sm:space-y-5">
            <div>
              <h3 className="text-lg font-semibold mb-1">Starter</h3>
              <p className="text-sm text-muted-foreground">Perfect for small to medium contractors</p>
            </div>

            <div>
              <div className="flex items-baseline gap-1">
                <span className="text-3xl font-bold">${getPrice('starter')}</span>
                <span className="text-sm text-muted-foreground">/mo</span>
              </div>
              {isAnnual && (
                <p className="text-xs text-primary mt-1">Billed annually</p>
              )}
            </div>

            <ul className="space-y-2.5">
              <li className="flex items-start gap-2">
                <div className="mt-1 h-4 w-4 rounded-full bg-primary/10 flex items-center justify-center flex-shrink-0">
                  <Check className="h-2.5 w-2.5 text-primary" />
                </div>
                <span className="text-sm">All features included</span>
              </li>
              <li className="flex items-start gap-2">
                <div className="mt-1 h-4 w-4 rounded-full bg-primary/10 flex items-center justify-center flex-shrink-0">
                  <Check className="h-2.5 w-2.5 text-primary" />
                </div>
                <span className="text-sm">Unlimited quotes</span>
              </li>
              <li className="flex items-start gap-2">
                <div className="mt-1 h-4 w-4 rounded-full bg-primary/10 flex items-center justify-center flex-shrink-0">
                  <Check className="h-2.5 w-2.5 text-primary" />
                </div>
                <span className="text-sm">Email & chat support</span>
              </li>
            </ul>

            <Button className="w-full" variant="outline">Coming Soon</Button>
          </div>
        </Card>

        {/* Professional Plan */}
        <Card className="relative overflow-hidden bg-card/80 backdrop-blur-sm">
          <div className="p-4 sm:p-6 space-y-4 sm:space-y-5">
            <div>
              <h3 className="text-lg font-semibold mb-1">Professional</h3>
              <p className="text-sm text-muted-foreground">For growing contractors with multiple locations</p>
            </div>

            <div>
              <div className="flex items-baseline gap-1">
                <span className="text-3xl font-bold">TBD</span>
              </div>
            </div>

            <ul className="space-y-2.5">
              <li className="flex items-start gap-2">
                <div className="mt-1 h-4 w-4 rounded-full bg-primary/10 flex items-center justify-center flex-shrink-0">
                  <Check className="h-2.5 w-2.5 text-primary" />
                </div>
                <span className="text-sm">Everything in Starter</span>
              </li>
              <li className="flex items-start gap-2">
                <div className="mt-1 h-4 w-4 rounded-full bg-primary/10 flex items-center justify-center flex-shrink-0">
                  <Check className="h-2.5 w-2.5 text-primary" />
                </div>
                <span className="text-sm">Priority support</span>
              </li>
              <li className="flex items-start gap-2">
                <div className="mt-1 h-4 w-4 rounded-full bg-primary/10 flex items-center justify-center flex-shrink-0">
                  <Check className="h-2.5 w-2.5 text-primary" />
                </div>
                <span className="text-sm">Advanced analytics</span>
              </li>
            </ul>

            <Button className="w-full" variant="outline">Coming Soon</Button>
          </div>
        </Card>
      </div>
    </div>
  );
}
