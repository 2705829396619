import { 
  ArrowRight, 
  ArrowLeft,
  CalendarDays, 
  FileText, 
  Clock, 
  CheckCircle,
  Check,
  Zap,
  MessageSquare,
  DollarSign
} from "lucide-react";

// Navigation Icons
export const IconArrowRight = ({ className = "ml-2 h-5 w-5" }: { className?: string }) => (
  <ArrowRight className={className} />
);

export const IconArrowLeft = ({ className = "h-5 w-5" }: { className?: string }) => (
  <ArrowLeft className={className} />
);

// Action Icons
export const IconCalendar = ({ className = "h-6 w-6" }: { className?: string }) => (
  <CalendarDays className={className} />
);

export const IconDocument = ({ className = "h-6 w-6" }: { className?: string }) => (
  <FileText className={className} />
);

export const IconClock = ({ className = "h-3 w-3" }: { className?: string }) => (
  <Clock className={className} />
);

export const IconCheck = ({ className = "h-8 w-8" }: { className?: string }) => (
  <Check className={className} />
);

export const IconCheckCircle = ({ className = "h-3.5 w-3.5" }: { className?: string }) => (
  <CheckCircle className={className} />
);

export const IconZap = ({ className = "h-8 w-8" }: { className?: string }) => (
  <Zap className={className} />
);

export const IconMessageSquare = ({ className = "h-6 w-6" }: { className?: string }) => (
  <MessageSquare className={className} />
);

export const IconDollarSign = ({ className = "h-5 w-5" }: { className?: string }) => (
  <DollarSign className={className} />
);

// For consistency with the file naming
export const IconFileText = IconDocument;
