import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { ArrowLeft, Check, Phone, Calendar } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import Cal, { getCalApi } from "@calcom/embed-react";

interface ConfirmationStepProps {
  formData: {
    selectedServices: string[];
    contactDetails: {
      email: string;
      name: string;
      companyName: string;
      phoneNumber?: string;
    };
    message: string;
  };
  onBack: () => void;
}

export function ConfirmationStep({
  formData,
  onBack,
}: ConfirmationStepProps) {
  const [preferredContact, setPreferredContact] = useState<"quick" | "detailed">("detailed");
  const { toast } = useToast();

  useEffect(() => {
    (async function () {
      const calDetailed = await getCalApi({ namespace: "detailed-technology-consultation" });
      calDetailed("ui", { 
        hideEventTypeDetails: false, 
        layout: "month_view" 
      });

      const calQuick = await getCalApi({ namespace: "15min" });
      calQuick("ui", { 
        hideEventTypeDetails: false, 
        layout: "month_view" 
      });
      
      const cal = await getCalApi();
      cal("on", {
        action: "bookingSuccessful",
        callback: async (event) => {
          try {
            const consultationType = event.data.eventType.slug.includes("15min") ? "quick" : "detailed";
            
            const response = await fetch(import.meta.env.NEXT_PUBLIC_ZAPIER_WEBHOOK_URL || '', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                type: "consultation_booking",
                formData: {
                  ...formData,
                  bookingDetails: event,
                  consultationType,
                  hubspotProperties: {
                    consultation_type: consultationType,
                    consultation_scheduled: "true",
                    consultation_date: event.data.date,
                    lifecycle_stage: "sales_qualified_lead",
                    lead_status: consultationType === "quick" ? "discovery_call_scheduled" : "consultation_scheduled"
                  }
                }
              }),
            });

            if (!response.ok) throw new Error("Failed to process booking");

            toast({
              title: `${consultationType === "quick" ? "Discovery Call" : "Consultation"} Scheduled!`,
              description: "We'll send you a confirmation email with meeting details shortly.",
            });
          } catch (error) {
            console.error('Failed to process booking:', error);
            toast({
              title: "Error",
              description: "Failed to process booking. Please try again or contact support.",
              variant: "destructive",
            });
          }
        },
      });
    })();
  }, [formData, toast]);

  return (
    <div className="space-y-6">
      <div className="text-center">
        <h2 className="text-2xl font-bold mb-2">Schedule Your Consultation</h2>
        <p className="text-muted-foreground">
          Choose the type of consultation that best fits your needs.
        </p>
      </div>

      <RadioGroup
        value={preferredContact}
        onValueChange={(value: "quick" | "detailed") => setPreferredContact(value)}
        className="grid gap-4"
      >
        <div className="relative">
          <RadioGroupItem value="detailed" id="detailed" className="peer sr-only" />
          <Label
            htmlFor="detailed"
            className="flex flex-col gap-2 p-4 rounded-lg border-2 cursor-pointer peer-data-[state=checked]:border-primary hover:border-primary/50 transition-colors"
          >
            <div className="font-semibold flex items-center gap-2">
              <Calendar className="h-5 w-5" />
              Detailed Technology Consultation
            </div>
            <p className="text-sm text-muted-foreground">
              Schedule a comprehensive 45-minute consultation to deep dive into your needs and explore solutions.
            </p>
          </Label>
        </div>

        <div className="relative">
          <RadioGroupItem value="quick" id="quick" className="peer sr-only" />
          <Label
            htmlFor="quick"
            className="flex flex-col gap-2 p-4 rounded-lg border-2 cursor-pointer peer-data-[state=checked]:border-primary hover:border-primary/50 transition-colors"
          >
            <div className="font-semibold flex items-center gap-2">
              <Phone className="h-5 w-5" />
              Quick Discovery Call
            </div>
            <p className="text-sm text-muted-foreground">
              Schedule a focused 15-minute call to discuss your immediate needs and get quick guidance.
            </p>
          </Label>
        </div>
      </RadioGroup>

      <div className="mt-6" key={preferredContact}>
        <Cal
          key={preferredContact}
          namespace={preferredContact === "detailed" ? "detailed-technology-consultation" : "15min"}
          calLink={`proposalstream/${preferredContact === "detailed" ? "detailed-technology-consultation" : "15min"}`}
          style={{ width: "100%", height: "100%", overflow: "scroll" }}
          config={{
            layout: "month_view",
            name: formData.contactDetails.name,
            email: formData.contactDetails.email,
            notes: `Company: ${formData.contactDetails.companyName}\nServices: ${formData.selectedServices.join(", ")}\nMessage: ${formData.message}`,
          }}
        />
      </div>

      <div className="flex justify-start">
        <Button variant="outline" onClick={onBack}>
          Back
        </Button>
      </div>
    </div>
  );
}