import { useState, useEffect } from "react";
import { Button, Input, Card } from "@/components/ui";
import { useToast } from "@/hooks/use-toast";
import { GoogleSignInButton } from "@/components/GoogleSignIn";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { useUser } from "@/hooks/use-user";
import { useLocation } from "wouter";

const ADMIN_EMAILS = ['guymaxphelps@gmail.com']; // Replace with actual admin emails

export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { toast } = useToast();
  const { user, isLoading: isUserLoading } = useUser();
  const [, navigate] = useLocation();
  const [loginError, setLoginError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isUserLoading && user) {
      navigate("/dashboard");
    }
  }, [user, isUserLoading, navigate]);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoginError(null);
    setIsLoading(true);

    try {
      const backendUrl = import.meta.env.VITE_BACKEND_URL || 'https://api.proposal-stream.com';
      const response = await fetch(`${backendUrl}/api/auth/login`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to login');
      }

      const data = await response.json();
      
      if (data.status === 'success') {
        toast({
          title: "Welcome back!",
          description: "Successfully logged in.",
        });
        navigate('/dashboard');
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error("Login error:", error);
      setLoginError(error instanceof Error ? error : new Error('Failed to login'));
      toast({
        title: "Login Failed",
        description: error instanceof Error ? error.message : "Invalid email or password. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSuccess = async (response: any) => {
    setLoginError(null);
    setIsLoading(true);
    
    try {
      const backendUrl = import.meta.env.VITE_BACKEND_URL || 'https://api.proposal-stream.com';
      
      console.log('Google response:', {
        timestamp: new Date().toISOString(),
        origin: window.location.origin,
        backendUrl
      });

      const loginResponse = await fetch(`${backendUrl}/api/auth/google`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Origin': window.location.origin,
          'X-Requested-With': 'XMLHttpRequest'
        },
        body: JSON.stringify({
          token: response.credential,
        }),
      });

      console.log('Auth response:', {
        status: loginResponse.status,
        headers: Object.fromEntries(loginResponse.headers.entries()),
        url: loginResponse.url,
        origin: window.location.origin,
        timestamp: new Date().toISOString()
      });

      if (!loginResponse.ok) {
        const errorData = await loginResponse.json();
        throw new Error(errorData.message || 'Authentication failed');
      }

      const data = await loginResponse.json();
      
      if (data.status === 'success') {
        toast({
          title: "Welcome!",
          description: "Successfully signed in with Google.",
        });
        
        // Add a small delay to ensure session is established
        await new Promise(resolve => setTimeout(resolve, 500));
        navigate('/dashboard');
      } else {
        throw new Error(data.message || 'Authentication failed');
      }
    } catch (error) {
      console.error('Google auth error:', {
        error,
        origin: window.location.origin,
        timestamp: new Date().toISOString()
      });
      
      setLoginError(error instanceof Error ? error : new Error('Failed to authenticate'));
      toast({
        title: "Authentication Failed",
        description: error instanceof Error ? error.message : "Failed to authenticate with Google. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleError = (error: Error) => {
    console.error('Google Sign-In Error:', {
      error,
      origin: window.location.origin,
      timestamp: new Date().toISOString()
    });
    
    setLoginError(error);
    toast({
      title: "Google Sign-In Failed",
      description: error.message || "Failed to initialize Google Sign-In. Please try again.",
      variant: "destructive",
    });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-background py-12 px-4 sm:px-6 lg:px-8">
      <Card className="max-w-md w-full p-8 shadow-lg">
        <h2 className="text-2xl font-semibold text-center mb-6">Login to Your Account</h2>
        <form onSubmit={handleLogin} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email Address
            </label>
            <Input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Enter your email address"
              className="mt-1"
              disabled={isLoading}
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <Input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Enter your password"
              className="mt-1"
              disabled={isLoading}
            />
          </div>
          <Button type="submit" className="w-full" disabled={isLoading}>
            {isLoading ? (
              <div className="flex items-center justify-center">
                <div className="h-4 w-4 animate-spin rounded-full border-2 border-current border-t-transparent mr-2" />
                Signing in...
              </div>
            ) : (
              "Sign in with Email"
            )}
          </Button>
        </form>
        
        <div className="relative my-6">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-border/50"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-background text-foreground/60">Or continue with</span>
          </div>
        </div>

        <div className="flex justify-center">
          <ErrorBoundary>
            <GoogleSignInButton 
              onSuccess={handleGoogleSuccess}
              onError={handleGoogleError}
            />
          </ErrorBoundary>
        </div>
        
        {loginError && (
          <div className="mt-4 text-sm text-center text-red-600">
            {loginError.message}
            <Button
              variant="link"
              className="ml-2 text-sm"
              onClick={() => {
                setLoginError(null);
              }}
            >
              Retry
            </Button>
          </div>
        )}

        <div className="mt-6 text-center text-sm">
          <span className="text-foreground/60">Don't have an account?</span>{" "}
          <Button variant="link" className="p-0" onClick={() => navigate("/signup")}>
            Sign Up
          </Button>
        </div>
      </Card>
    </div>
  );
}