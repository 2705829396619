import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { useToast } from "@/hooks/use-toast";
import { useUser } from "@/hooks/use-user";
import { useLocation } from "wouter";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { AuthForm } from "@/components/AuthForm";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { 
  IconArrowRight,
  IconCheck,
  IconFileText,
  IconZap,
  IconMessageSquare,
  IconCheckCircle,
  IconDollarSign
} from "@/components/ui/icons";
import { Header } from "@/components/Header";
import { InteractiveQA } from "@/components/InteractiveQA";
import { ServiceOfferings } from "@/components/ServiceOfferings";
import { ServiceSelectionFlow } from "@/components/ServiceSelection/ServiceSelectionFlow";
import { VideoPlayer } from "@/components/VideoPlayer";
import { AuditOptionsDialog } from "@/components/AuditOptionsDialog";
import { ComparisonSlider } from "@/components/ComparisonSlider"; // Import ComparisonSlider component

// Add gtag type definition
declare global {
  interface Window {
    gtag: (command: string, ...args: any[]) => void;
  }
}

interface GoogleCredentialResponse {
  credential: string;
}

interface GoogleSignInButtonProps {
  onError: (error: Error) => void;
}

interface GoogleSignInFallbackProps {
  error: Error;
  onRetry: () => void;
}

const RETRY_DELAYS = [1000, 2000, 4000, 8000];

function GoogleSignInButton({ onError }: GoogleSignInButtonProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [isGoogleInitialized, setIsGoogleInitialized] = useState(false);
  const [initializationAttempt, setInitializationAttempt] = useState(0);
  const [initError, setInitError] = useState<Error | null>(null);
  const { handleGoogleLogin } = useUser();
  const { toast } = useToast();

  const initializeGoogleSignIn = async () => {
    try {
      await new Promise(resolve => setTimeout(resolve, 100));

      if (!window.google?.accounts?.id) {
        console.debug('Google Sign-In SDK not loaded, retrying...', {
          attempt: initializationAttempt,
          window: typeof window !== 'undefined',
          google: Boolean(window.google),
          accounts: Boolean(window.google?.accounts),
        });
        
        if (initializationAttempt >= RETRY_DELAYS.length) {
          throw new Error("Failed to load Google Sign-In after multiple attempts");
        }

        const delay = RETRY_DELAYS[initializationAttempt];
        setTimeout(() => {
          setInitializationAttempt(prev => prev + 1);
          initializeGoogleSignIn();
        }, delay);
        return;
      }

      const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
      if (!clientId) {
        throw new Error("Google Client ID not configured");
      }

      const origin = window.location.origin;
      console.debug('Initializing Google Sign-In:', {
        origin,
        clientId: clientId.substring(0, 8) + '...',
      });

      window.google.accounts.id.initialize({
        client_id: clientId,
        callback: async (response: GoogleCredentialResponse) => {
          try {
            setIsLoading(true);
            await handleGoogleLogin(response.credential);
            // Track successful Google sign in
            window.gtag('event', 'login', {
              method: 'Google'
            });
          } catch (error) {
            console.error('Google Sign-In error:', error);
            onError(error instanceof Error ? error : new Error('Authentication failed'));
            // Track failed Google sign in
            window.gtag('event', 'exception', {
              description: 'Google Sign-In Error',
              fatal: false
            });
          } finally {
            setIsLoading(false);
          }
        },
        allowed_parent_origin: origin,
        auto_select: false,
        cancel_on_tap_outside: true,
      });

      setIsGoogleInitialized(true);
      setIsLoading(false);
    } catch (error) {
      console.error("Google Sign-In initialization error:", error);
      setInitError(error instanceof Error ? error : new Error("Failed to initialize Google Sign-In"));
      setIsLoading(false);
      // Track initialization error
      window.gtag('event', 'exception', {
        description: 'Google Sign-In Initialization Error',
        fatal: false
      });
    }
  };

  useEffect(() => {
    const buttonElement = document.getElementById("google-signin");
    if (isGoogleInitialized && window.google?.accounts?.id && buttonElement) {
      window.google.accounts.id.renderButton(buttonElement, {
        theme: "outline",
        size: "large",
        type: "standard",
        text: "signin_with",
        shape: "rectangular",
        width: 300,
      });
    }
  }, [isGoogleInitialized]);

  useEffect(() => {
    initializeGoogleSignIn();
    return () => {
      if (window.google?.accounts?.id && isGoogleInitialized) {
        window.google.accounts.id.cancel();
      }
    };
  }, [handleGoogleLogin, initializationAttempt]);

  if (initError) {
    return (
      <div className="p-4 text-center">
        <p className="text-destructive">{initError.message}</p>
        <Button 
          onClick={() => {
            setInitError(null);
            setInitializationAttempt(0);
            initializeGoogleSignIn();
          }}
          className="mt-4"
        >
          Retry
        </Button>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center p-4">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="relative min-h-[48px]">
      <div id="google-signin" className="w-full flex justify-center"></div>
    </div>
  );
}

function GoogleSignInFallback({ error, onRetry }: GoogleSignInFallbackProps) {
  return (
    <div className="p-4 text-center">
      <p className="text-destructive mb-4">{error.message}</p>
      <Button onClick={onRetry}>Try Again</Button>
    </div>
  );
}

function LandingPageContent() {
  const [, navigate] = useLocation();
  const [showAuditOptions, setShowAuditOptions] = useState(false);
  const { toast } = useToast();

  const handleStartAudit = () => {
    setShowAuditOptions(true);
  };

  const handleBookCall = () => {
    setShowAuditOptions(false);
    navigate('/book-call');
  };

  const handleFillSurvey = () => {
    // Open survey in new tab
    window.open('https://docs.google.com/forms/d/e/1FAIpQLScgASHBfJv4BwI8w1nDbJgtvQ5ZBFWY1yYhS17SMZdRDUTGoQ/viewform?usp=dialog', '_blank');
    
    // Close the dialog
    setShowAuditOptions(false);
    
    // Navigate to book-call page with survey completion message
    navigate('/book-call?from=survey');
    
    // Show toast message
    toast({
      title: "Survey Opened",
      description: "Complete the survey to speed up your audit process. You can book your call right after!",
      duration: 8000,
    });
  };

  const content = (
    <main className="relative w-full overflow-x-hidden">
      <Header />

      {/* Hero Section */}
      <div className="min-h-[calc(100vh-4rem)] relative flex flex-col bg-gradient-to-b from-background via-primary/5 to-background">
        <div className="absolute inset-0 bg-grid-primary/[0.02] -z-10" />
        <div 
          className="absolute inset-0 bg-gradient-to-b from-transparent via-primary/5 to-transparent -z-10"
          style={{
            maskImage: 'linear-gradient(to bottom, transparent, black, transparent)',
            WebkitMaskImage: 'linear-gradient(to bottom, transparent, black, transparent)',
          }}
        />
        <div className="flex-grow flex items-center justify-center relative -mt-16">
          <div className="container mx-auto px-4 grid lg:grid-cols-2 gap-12 lg:gap-20 items-center py-8 lg:py-16">
            <div className="space-y-10">
              <div className="space-y-8">
                <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold tracking-tight">
                  <span className="text-gradient">Free Tech Stack Audit</span>{" "}
                  <span className="inline-block">for Commercial Contractors</span>
                </h1>
                <p className="text-xl text-muted-foreground leading-relaxed max-w-xl">
                  Get a comprehensive evaluation of your technology infrastructure and discover opportunities for automation, efficiency, and growth.
                </p>
              </div>
              <div className="flex flex-col sm:flex-row gap-4">
                <Button
                  size="lg"
                  onClick={handleStartAudit}
                  className="group relative btn-primary text-lg px-8 py-6 shadow-lg shadow-primary/20 hover:shadow-xl hover:shadow-primary/30 transition-all duration-300"
                >
                  Start Free Audit
                  <IconArrowRight className="ml-2 h-5 w-5 transition-transform group-hover:translate-x-0.5" />
                </Button>
                <Button
                  size="lg"
                  variant="secondary"
                  onClick={() => navigate('/audit-process')}
                  className="group relative text-lg px-8 py-6 bg-secondary text-secondary-foreground hover:bg-secondary/90 transition-all duration-300"
                >
                  Learn More
                  <IconArrowRight className="ml-2 h-5 w-5 transition-transform group-hover:translate-x-0.5" />
                </Button>
              </div>
            </div>
            <div className="lg:pl-8 flex flex-col justify-center">
              <div className="relative">
                {/* Background Effects */}
                <div className="absolute -inset-4 bg-gradient-to-br from-primary/5 via-transparent to-primary/5 rounded-xl blur-lg" />
                <div className="absolute inset-0 bg-gradient-to-br from-primary/5 via-transparent to-primary/5 rounded-xl" />
                
                {/* Content */}
                <div className="relative bg-card/90 backdrop-blur-md rounded-xl p-6 lg:p-8 shadow-2xl border border-primary/20">
                  <div className="flex items-center gap-3 mb-6">
                    <h3 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-primary/80">
                      Transform Your Tech Stack
                    </h3>
                  </div>

                  {/* Comparison Slider */}
                  <ComparisonSlider 
                    className="shadow-xl rounded-lg overflow-hidden border border-primary/10"
                  />

                  {/* Benefits Grid */}
                  <div className="mt-8 grid grid-cols-2 gap-4 lg:gap-6">
                    <div className="space-y-2">
                      <div className="flex items-center gap-2 text-sm font-medium">
                        <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center flex-shrink-0">
                          <IconCheckCircle className="w-4 h-4 text-primary" />
                        </div>
                        <span className="text-foreground">Reduced Complexity</span>
                      </div>
                      <p className="text-xs text-muted-foreground pl-10">
                        Streamline your workflow with integrated systems
                      </p>
                    </div>
                    <div className="space-y-2">
                      <div className="flex items-center gap-2 text-sm font-medium">
                        <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center flex-shrink-0">
                          <IconCheckCircle className="w-4 h-4 text-primary" />
                        </div>
                        <span className="text-foreground">Better Integration</span>
                      </div>
                      <p className="text-xs text-muted-foreground pl-10">
                        Seamless data flow between all systems
                      </p>
                    </div>
                    <div className="space-y-2">
                      <div className="flex items-center gap-2 text-sm font-medium">
                        <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center flex-shrink-0">
                          <IconCheckCircle className="w-4 h-4 text-primary" />
                        </div>
                        <span className="text-foreground">Cost Savings</span>
                      </div>
                      <p className="text-xs text-muted-foreground pl-10">
                        Eliminate redundant tools and licenses
                      </p>
                    </div>
                    <div className="space-y-2">
                      <div className="flex items-center gap-2 text-sm font-medium">
                        <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center flex-shrink-0">
                          <IconCheckCircle className="w-4 h-4 text-primary" />
                        </div>
                        <span className="text-foreground">Enhanced Security</span>
                      </div>
                      <p className="text-xs text-muted-foreground pl-10">
                        Centralized control and monitoring
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Video Demo Section */}
      <section className="py-8 sm:py-12 bg-gradient-to-b from-background via-secondary/20 to-background relative overflow-hidden">
        <div className="absolute inset-0 bg-grid-primary/[0.02] -z-10" />
        <div 
          className="absolute inset-0 bg-gradient-to-b from-transparent via-secondary/20 to-transparent -z-10"
          style={{
            maskImage: 'linear-gradient(to bottom, transparent, black, transparent)',
            WebkitMaskImage: 'linear-gradient(to bottom, transparent, black, transparent)',
          }}
        />
        <div className="container mx-auto px-4 relative">
          <VideoPlayer
            src="/videos/AutoQuoteDemo.mp4"
            title="AutoQuote Demo - Blazing Fast Quote Responses"
            description="See how our AI-powered system instantly transforms customer quote requests into accurate, professional estimates, giving your business blazing response time."
          />
        </div>
      </section>

      {/* Features Section */}
      <section className="py-16 sm:py-20 md:py-24 relative">
        <div className="absolute inset-0 bg-gradient-to-b from-background via-secondary/20 to-background -z-10" />
        <div className="absolute inset-0 bg-grid-primary/[0.02] -z-10" />
        <div className="container mx-auto px-3 sm:px-8">
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-8 sm:mb-12 md:mb-16 text-center">Why Contractors Choose AutoQuote</h2>
          <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4 sm:gap-6 md:gap-8">
            <Card className="p-4 sm:p-6 bg-card/80 backdrop-blur-sm shadow-xl hover:shadow-2xl transition-all duration-500 border-primary/10">
              <IconZap className="h-8 w-8 sm:h-12 sm:w-12 text-primary mb-3 sm:mb-4" />
              <h3 className="text-lg sm:text-xl font-semibold mb-2">Built for Speed</h3>
              <p className="text-sm sm:text-base text-muted-foreground">
                Deliver professional quotes in minutes. Our automated system helps you respond faster to RFPs and win more commercial contracts.
              </p>
            </Card>
            <Card className="p-4 sm:p-6 bg-card/80 backdrop-blur-sm shadow-xl hover:shadow-2xl transition-all duration-500 border-primary/10">
              <IconCheck className="h-8 w-8 sm:h-12 sm:w-12 text-primary mb-3 sm:mb-4" />
              <h3 className="text-lg sm:text-xl font-semibold mb-2">Property Manager Approved</h3>
              <p className="text-sm sm:text-base text-muted-foreground">
                Created by property management professionals who understand what makes vendor relationships successful. Every feature is built with this insight.
              </p>
            </Card>
            <Card className="p-4 sm:p-6 bg-card/80 backdrop-blur-sm shadow-xl hover:shadow-2xl transition-all duration-500 border-primary/10">
              <IconFileText className="h-8 w-8 sm:h-12 sm:w-12 text-primary mb-3 sm:mb-4" />
              <h3 className="text-lg sm:text-xl font-semibold mb-2">Smart Templates</h3>
              <p className="text-sm sm:text-base text-muted-foreground">
                Industry-specific templates for roofing, landscaping, and maintenance, designed to showcase your services effectively to property managers.
              </p>
            </Card>
          </div>
        </div>
      </section>

      {/* Service Offerings Preview */}
      <section id="services" className="py-16 sm:py-20 md:py-24 relative">
        <div className="absolute inset-0 bg-gradient-to-b from-background via-primary/5 to-background -z-10" />
        <div className="absolute inset-0 bg-grid-primary/[0.02] -z-10" />
        <div 
          className="absolute inset-0 bg-gradient-to-b from-transparent via-primary/5 to-transparent -z-10"
          style={{
            maskImage: 'linear-gradient(to bottom, transparent, black, transparent)',
            WebkitMaskImage: 'linear-gradient(to bottom, transparent, black, transparent)',
          }}
        />
        <div className="container mx-auto px-3 sm:px-8">
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-3 sm:mb-4 text-center">More Services for Commercial Contractors</h2>
          <p className="text-sm sm:text-base md:text-lg text-muted-foreground mb-8 sm:mb-12 md:mb-16 text-center max-w-[95%] mx-auto sm:max-w-none">
            Modern solutions to help you win more bids and stay sharp in your market
          </p>
          <div className="-mx-3 sm:mx-0 overflow-hidden">
            <div className="px-3 sm:px-0">
              <ServiceOfferings />
            </div>
          </div>
          <div className="text-center mt-6 sm:mt-8">
            <Button 
              size="lg" 
              onClick={() => navigate('/products')} 
              className="btn-primary text-lg px-8"
            >
              View All Services
              <IconArrowRight className="ml-2 h-5 w-5" />
            </Button>
          </div>
        </div>
      </section>

      {/* Service Selection Section */}
      <section className="py-12 sm:py-16 md:py-20 bg-gradient-to-b from-background via-secondary/20 to-background -mt-16 sm:-mt-20 md:-mt-24">
        <div className="container mx-auto px-3 sm:px-8">
          <ServiceSelectionFlow />
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 sm:py-28 relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-background via-secondary/10 to-background -z-10" />
        <div className="absolute inset-0 bg-grid-primary/[0.02] -z-10" />
        
        {/* Decorative elements */}
        <div className="absolute top-0 left-0 w-full h-px bg-gradient-to-r from-transparent via-primary/20 to-transparent" />
        <div className="absolute bottom-0 left-0 w-full h-px bg-gradient-to-r from-transparent via-primary/20 to-transparent" />
        
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="max-w-4xl mx-auto">
            <div className="relative">
              {/* Background card with blur effect */}
              <div className="absolute inset-0 bg-gradient-to-r from-primary/5 via-primary/10 to-primary/5 rounded-3xl blur-xl" />
              
              {/* Content */}
              <div className="relative bg-background/80 backdrop-blur-sm rounded-3xl border border-primary/10 p-8 sm:p-12">
                <div className="text-center space-y-6">
                  <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold">
                    Ready to <span className="text-gradient">Transform</span> Your Tech Stack?
                  </h2>
                  <p className="text-lg sm:text-xl text-muted-foreground max-w-2xl mx-auto">
                    Get a comprehensive evaluation of your technology infrastructure and discover opportunities for automation, efficiency, and growth.
                  </p>
                  <div className="pt-4">
                    <Button 
                      size="lg"
                      onClick={handleStartAudit}
                      className="btn-primary text-lg px-10 py-6 shadow-lg shadow-primary/20 hover:shadow-xl hover:shadow-primary/30 transition-all duration-300"
                    >
                      Start Free Audit
                      <IconArrowRight />
                    </Button>
                  </div>
                  <p className="text-sm text-muted-foreground pt-4">
                    No commitment required • 100% Free • Personalized recommendations
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );

  return (
    <>
      {content}
      <AuditOptionsDialog
        open={showAuditOptions}
        onOpenChange={setShowAuditOptions}
        onBookCall={handleBookCall}
        onFillSurvey={handleFillSurvey}
      />
    </>
  );
}

export default function LandingPage() {
  useEffect(() => {
    // Track page view on component mount
    window.gtag('config', 'G-ZZW26HF1JN', {
      page_path: window.location.pathname,
      page_title: 'Landing Page'
    });
  }, []);

  return (
    <ErrorBoundary>
      <LandingPageContent />
    </ErrorBoundary>
  );
}