import useSWR from "swr";
import { useToast } from "@/hooks/use-toast";
import type { User } from "../../../server/db/schema";
import { FetchError } from "@/lib/fetcher";

interface AuthResponse {
  status: "success" | "error";
  user?: User;
  error?: string;
  message?: string;
}

interface RegisterData {
  email: string;
  password: string;
  name: string;
}

interface LoginData {
  email: string;
  password: string;
}

const ALLOWED_ORIGINS = [
  'https://proposal-stream.com',
  'https://www.proposal-stream.com',
  'http://localhost:5173'
];

export function useUser() {
  const { data, error, mutate } = useSWR<AuthResponse>("/api/auth/user", async (url) => {
    try {
      const backendUrl = import.meta.env.VITE_BACKEND_URL || 'https://api.proposal-stream.com';
      const response = await fetch(`${backendUrl}${url}`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Origin': window.location.origin,
          'X-Requested-With': 'XMLHttpRequest'
        }
      });
      
      console.log('Auth check response:', {
        status: response.status,
        headers: Object.fromEntries(response.headers.entries()),
        url: response.url,
        origin: window.location.origin,
        backendUrl,
        timestamp: new Date().toISOString()
      });
      
      if (response.status === 401) {
        // Clear any stale session data
        document.cookie = "ps.sid=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
        throw new FetchError('Not authenticated', { message: 'Please log in' }, 401);
      }
      
      if (!response.ok) {
        const info = await response.json();
        throw new FetchError(
          info.message || 'Failed to fetch user',
          info,
          response.status
        );
      }
      
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Auth check error:', {
        error,
        origin: window.location.origin,
        timestamp: new Date().toISOString()
      });
      
      if (error instanceof FetchError) {
        throw error;
      }
      throw new FetchError(
        error instanceof Error ? error.message : 'Failed to fetch user',
        error,
        500
      );
    }
  }, {
    revalidateOnFocus: true,
    shouldRetryOnError: false,
    revalidateOnReconnect: true,
    dedupingInterval: 5000
  });

  const { toast } = useToast();

  const handleGoogleLogin = async (credential: string) => {
    try {
      console.log('Starting Google login process...');
      
      const backendUrl = import.meta.env.VITE_BACKEND_URL || 'https://api.proposal-stream.com';
      const response = await fetch(`${backendUrl}/api/auth/google`, {
        method: "POST",
        credentials: 'include',
        headers: { 
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Origin": window.location.origin,
          "X-Requested-With": "XMLHttpRequest"
        },
        body: JSON.stringify({ token: credential }),
      });

      console.log('Google auth response:', {
        status: response.status,
        headers: Object.fromEntries(response.headers.entries()),
        url: response.url,
        origin: window.location.origin,
        backendUrl,
        timestamp: new Date().toISOString()
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Google authentication failed');
      }

      // Force revalidation of user data
      await mutate();
      return true;
    } catch (error) {
      console.error('Google login error:', {
        error,
        origin: window.location.origin,
        timestamp: new Date().toISOString()
      });
      throw error;
    }
  };

  const register = async (data: RegisterData) => {
    try {
      const backendUrl = import.meta.env.VITE_BACKEND_URL || 'https://api.proposal-stream.com';
      const response = await fetch(`${backendUrl}/api/auth/register`, {
        method: "POST",
        credentials: 'include',
        headers: { 
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Origin": window.location.origin
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if (result.status === "error") {
        throw new Error(result.message || "Registration failed");
      }

      await mutate();
      toast({
        title: "Success",
        description: "Account created successfully",
      });
      
      return result;
    } catch (error) {
      console.error('Registration error:', error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to register",
        variant: "destructive",
      });
      throw error;
    }
  };

  const login = async (data: LoginData) => {
    try {
      const backendUrl = import.meta.env.VITE_BACKEND_URL || 'https://api.proposal-stream.com';
      const response = await fetch(`${backendUrl}/api/auth/login`, {
        method: "POST",
        credentials: 'include',
        headers: { 
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Origin": window.location.origin
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if (result.status === "error") {
        throw new Error(result.message || "Login failed");
      }

      await mutate();
      toast({
        title: "Success",
        description: "Successfully signed in",
      });
      
      return result;
    } catch (error) {
      console.error('Login error:', error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to log in",
        variant: "destructive",
      });
      throw error;
    }
  };

  const logout = async () => {
    try {
      const backendUrl = import.meta.env.VITE_BACKEND_URL || 'https://api.proposal-stream.com';
      const response = await fetch(`${backendUrl}/api/auth/logout`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Origin": window.location.origin
        }
      });
      
      const result = await response.json();
      
      if (result.status === "error") {
        throw new Error(result.message || "Logout failed");
      }
      
      await mutate(undefined);
      toast({
        title: "Success",
        description: "Logged out successfully",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to log out",
        variant: "destructive",
      });
    }
  };

  return {
    user: data?.data?.user,
    isLoading: !error && !data,
    isError: error,
    mutate,
    handleGoogleLogin,
    register,
    login,
    logout,
  };
}
