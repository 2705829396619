import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { 
  IconArrowRight,
  IconCalendar,
  IconDocument,
  IconClock,
  IconCheckCircle
} from "@/components/ui/icons";

interface AuditOptionsDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onBookCall: () => void;
  onFillSurvey: () => void;
}

export function AuditOptionsDialog({
  open,
  onOpenChange,
  onBookCall,
  onFillSurvey,
}: AuditOptionsDialogProps) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[500px] p-6">
        <DialogHeader className="space-y-3">
          <DialogTitle className="text-2xl font-bold">Start Your Free Tech Audit</DialogTitle>
          <DialogDescription className="text-base">
            Choose your preferred way to begin the audit process
          </DialogDescription>
        </DialogHeader>
        
        <div className="grid gap-4 py-6">
          <div className="space-y-4">
            <Button
              onClick={onFillSurvey}
              className="w-full p-4 h-auto flex items-center gap-4 group transition-all duration-300 bg-primary/5 hover:bg-primary/10 text-left"
              variant="ghost"
            >
              <div className="shrink-0">
                <div className="bg-primary/10 p-2 rounded-lg">
                  <IconDocument className="text-primary" />
                </div>
              </div>
              <div className="flex-1 min-w-0">
                <div className="flex items-center justify-between gap-2">
                  <div>
                    <h3 className="font-semibold text-lg leading-none mb-1">Quick Survey</h3>
                    <p className="text-sm text-muted-foreground">
                      Speed up your audit by up to 3 days
                    </p>
                  </div>
                  <IconArrowRight />
                </div>
                <div className="mt-2 flex items-center gap-2 text-xs text-muted-foreground">
                  <IconClock />
                  <span>5 min to complete</span>
                </div>
              </div>
            </Button>

            <Button
              onClick={onBookCall}
              className="w-full p-4 h-auto flex items-center gap-4 group transition-all duration-300 hover:bg-secondary/80"
              variant="secondary"
            >
              <div className="shrink-0">
                <div className="bg-secondary-foreground/10 p-2 rounded-lg">
                  <IconCalendar className="text-secondary-foreground" />
                </div>
              </div>
              <div className="flex-1 min-w-0">
                <div className="flex items-center justify-between gap-2">
                  <div>
                    <h3 className="font-semibold text-lg leading-none mb-1">Book Call Directly</h3>
                    <p className="text-sm text-muted-foreground">
                      Schedule a consultation with our team
                    </p>
                  </div>
                  <IconArrowRight />
                </div>
              </div>
            </Button>
          </div>

          <div className="text-xs text-muted-foreground mt-2">
            <p className="flex items-center gap-1.5">
              <IconCheckCircle className="text-primary" />
              <span>Your information is kept confidential and secure</span>
            </p>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
