import { useState } from "react";
import { useLocation, Link } from "wouter";
import { Button } from "@/components/ui/button";
import LogoLong from "@/assets/logos/LogoLong.png";
import { useTheme } from '../contexts/ThemeContext';
import { Sun, Moon, ChevronDown, Menu } from "lucide-react";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from "@/components/ui/dropdown-menu";

function ThemeToggle() {
  const { isDark, toggleTheme } = useTheme();
  
  return (
    <Button
      variant="ghost"
      size="icon"
      onClick={toggleTheme}
      className="w-9 h-9 rounded-full hover:bg-primary/10"
    >
      {isDark ? (
        <Sun className="h-5 w-5 text-primary" />
      ) : (
        <Moon className="h-5 w-5 text-primary" />
      )}
    </Button>
  );
}

export function Header() {
  const [, navigate] = useLocation();
  
  const handleLogoClick = () => {
    navigate('/');
    window.gtag('event', 'logo_click', {
      event_category: 'Navigation',
      event_label: 'Logo'
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  
  const handleNavigation = (path: string) => {
    window.gtag('event', 'page_view', {
      page_path: path
    });
    navigate(path);
  };
  
  return (
    <header className="sticky top-0 w-full overflow-x-hidden bg-background/80 border-b border-border/50 backdrop-blur-sm z-50">
      <nav className="container mx-auto px-3 sm:px-8">
        <div className="flex items-center justify-between h-14 sm:h-16">
          <div 
            className="flex-shrink-0 cursor-pointer transition-opacity hover:opacity-80" 
            onClick={handleLogoClick}
          >
            <img 
              src={LogoLong}
              alt="ProposalStream" 
              className="h-5 sm:h-8"
            />
          </div>

          {/* Desktop Navigation */}
          <nav className="hidden md:flex items-center space-x-8">
            <Link href="/">
              <a className="text-foreground/70 hover:text-foreground transition-colors duration-200 text-sm no-underline">Home</a>
            </Link>
            <a 
              href="/#services" 
              className="text-foreground/70 hover:text-foreground transition-colors duration-200 text-sm no-underline cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                if (window.location.pathname === '/') {
                  document.querySelector('#services')?.scrollIntoView({ behavior: 'smooth' });
                } else {
                  navigate('/?scrollTo=services');
                }
              }}
            >
              Services
            </a>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="link" className="text-foreground/70 hover:text-foreground transition-colors duration-200 p-0 h-auto font-normal text-sm">
                  Resources
                  <ChevronDown className="ml-1 h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="start" className="bg-card border border-border/50">
                <DropdownMenuItem className="focus:bg-primary/10">
                  <Link href="/future-projects">
                    <a className="w-full text-foreground/70 hover:text-foreground no-underline text-sm">Future Projects</a>
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem className="focus:bg-primary/10">
                  <Link href="/contact">
                    <a className="w-full text-foreground/70 hover:text-foreground no-underline text-sm">Contact</a>
                  </Link>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            <div className="flex items-center space-x-4">
              <ThemeToggle />
              {/* Login button temporarily disabled
              <Button
                variant="outline"
                className="border-border/50 text-foreground hover:bg-primary/10 hover:text-foreground hover:border-primary/50 transition-colors duration-200"
                onClick={() => handleNavigation('/login')}
              >
                Login
              </Button>
              */}
              <Button size="sm" onClick={() => navigate('/signup')}>
                Sign up
              </Button>
            </div>
          </nav>

          {/* Mobile Navigation */}
          <div className="flex md:hidden items-center space-x-2">
            <Button 
              size="sm"
              className="bg-primary text-primary-foreground hover:bg-primary/90 px-2.5 py-1.5 h-auto text-sm whitespace-nowrap"
              onClick={() => navigate('/book-call')}
            >
              Book a Call
            </Button>
            <ThemeToggle />
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon" className="text-foreground">
                  <Menu className="h-5 w-5" />
                  <span className="sr-only">Toggle menu</span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-[200px] bg-card border border-border/50">
                <DropdownMenuItem className="focus:bg-primary/10">
                  <Link href="/">
                    <a className="w-full text-foreground/70 hover:text-foreground no-underline text-sm">Home</a>
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem className="focus:bg-primary/10">
                  <a 
                    className="w-full text-foreground/70 hover:text-foreground no-underline text-sm cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      if (window.location.pathname === '/') {
                        document.querySelector('#services')?.scrollIntoView({ behavior: 'smooth' });
                      } else {
                        navigate('/?scrollTo=services');
                      }
                    }}
                  >
                    Services
                  </a>
                </DropdownMenuItem>
                <DropdownMenuItem className="focus:bg-primary/10">
                  <Link href="/future-projects">
                    <a className="w-full text-foreground/70 hover:text-foreground no-underline text-sm">Future Projects</a>
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem className="focus:bg-primary/10">
                  <Link href="/contact">
                    <a className="w-full text-foreground/70 hover:text-foreground no-underline text-sm">Contact</a>
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem className="focus:bg-primary/10">
                  <Link href="/login">
                    <a className="w-full text-foreground/70 hover:text-foreground no-underline text-sm">Log in</a>
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem className="focus:bg-primary/10">
                  <Link href="/signup">
                    <a className="w-full text-foreground/70 hover:text-foreground no-underline text-sm">Sign up</a>
                  </Link>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </nav>
    </header>
  );
}
