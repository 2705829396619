// client/src/components/ServiceSelection/ServiceSelectionFlow.tsx
import { useState } from "react";
import { useLocation } from "wouter";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Progress } from "@/components/ui/progress";
import { ServiceSelectionStep } from "./ServiceSelectionStep";
import { ContactDetailsStep } from "./ContactDetailsStep";
import { MessageStep } from "./MessageStep";
import { ConfirmationStep } from "./ConfirmationStep";
import { useToast } from "@/hooks/use-toast";

interface ServiceSelectionData {
  selectionType: 'audit' | 'specific';
  selectedServices: string[];
  contactDetails: {
    email: string;
    name: string;
    companyName: string;
    phoneNumber?: string;
    city?: string;
    state?: string;
    country: string;
    otherCountry?: string;
    companySize?: string;
  };
  message: string;
}

const AVAILABLE_SERVICES = [
  // Add your available services here
  // Example:
  // { id: 'service-1', name: 'Service 1' },
  // { id: 'service-2', name: 'Service 2' },
];

export function ServiceSelectionFlow() {
  const [currentStep, setCurrentStep] = useState(1);
  const { toast } = useToast();
  const [, setLocation] = useLocation();
  const [formData, setFormData] = useState<ServiceSelectionData>({
    selectionType: 'audit',
    selectedServices: [],
    contactDetails: {
      email: "",
      name: "",
      companyName: "",
      phoneNumber: "",
      city: "",
      state: "",
      country: "United States",
      otherCountry: "",
      companySize: "",
    },
    message: "",
  });

  const updateFormData = (data: Partial<ServiceSelectionData>) => {
    setFormData((prev) => ({
      ...prev,
      ...data,
    }));
  };

  const handleNext = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleSubmit = async () => {
    try {
      // Format data for Zapier/HubSpot
      const payload = {
        formType: formData.selectionType === 'audit' ? 'tech_audit_request' : 'direct_service_inquiry',
        services: formData.selectionType === 'audit' 
          ? ['Technology Stack Audit'] 
          : formData.selectedServices.map(serviceId => {
              const service = AVAILABLE_SERVICES.find(s => s.id === serviceId);
              return service?.name || serviceId;
            }),
        contact: {
          ...formData.contactDetails,
          message: formData.message,
          inquiry_source: 'website_service_selector',
          inquiry_type: formData.selectionType,
          lead_status: formData.selectionType === 'audit' ? 'audit_requested' : 'service_inquiry',
          lifecycle_stage: 'lead'
        },
        metadata: {
          submission_timestamp: new Date().toISOString(),
          form_location: window.location.pathname,
          selection_type: formData.selectionType
        }
      };

      const response = await fetch(import.meta.env.NEXT_PUBLIC_ZAPIER_WEBHOOK_URL || '', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to submit form');
      }

      // Reset form data
      setFormData({
        selectionType: 'audit',
        selectedServices: [],
        contactDetails: {
          email: "",
          name: "",
          companyName: "",
          phoneNumber: "",
          city: "",
          state: "",
          country: "United States",
          otherCountry: "",
          companySize: "",
        },
        message: "",
      });

      // Redirect to thank you page using wouter
      setLocation('/thank-you');

    } catch (error) {
      console.error('Form submission error:', error);
      toast({
        title: "Submission Error",
        description: "There was a problem submitting your request. Please try again or contact us directly.",
        variant: "destructive",
        duration: 7000,
      });
    }
  };

  return (
    <div className="w-full">
      <div className="max-w-3xl mx-auto">
        <div className="mb-6 sm:mb-8">
          <Progress value={(currentStep / 4) * 100} className="h-1.5 sm:h-2" />
        </div>

        <Card className="p-4 sm:p-6 md:p-8">
          {currentStep === 1 && (
            <ServiceSelectionStep
              selectedServices={formData.selectedServices}
              selectionType={formData.selectionType}
              onUpdate={(data) =>
                updateFormData(data)
              }
              onNext={handleNext}
            />
          )}
          {currentStep === 2 && (
            <ContactDetailsStep
              contactDetails={formData.contactDetails}
              selectedServices={formData.selectedServices}
              selectionType={formData.selectionType}
              onUpdate={(details) =>
                updateFormData({ contactDetails: details })
              }
              onNext={handleNext}
              onBack={handleBack}
            />
          )}
          {currentStep === 3 && (
            <MessageStep
              message={formData.message}
              onUpdate={(message) => updateFormData({ message })}
              onNext={handleNext}
              onBack={handleBack}
            />
          )}
          {currentStep === 4 && (
            <ConfirmationStep
              formData={formData}
              onBack={handleBack}
              onSubmit={handleSubmit}
              calendarUrl="YOUR_CAL_COM_URL"
            />
          )}
        </Card>
      </div>
    </div>
  );
}