import { useState, useRef, useEffect } from "react";
import ReactCompareImage from "react-compare-image";
import { cn } from "@/lib/utils";
import { useTheme } from "../contexts/ThemeContext";

// ---- Light Mode SVGs ---- //
const lightBeforeImage = `data:image/svg+xml,${encodeURIComponent(`
<svg xmlns="http://www.w3.org/2000/svg" width="800" height="450">
  <defs>
    <linearGradient id="warningGradient" x1="0%" y1="0%" x2="100%" y2="100%">
      <stop offset="0%" stop-color="hsl(0 84.2% 85%)"/>
      <stop offset="100%" stop-color="hsl(0 84.2% 75%)"/>
    </linearGradient>
  </defs>

  <rect width="800" height="450" fill="hsl(40 33% 98%)"/>
  
  <!-- Background Chaos Pattern -->
  <path d="M0 0 L800 450 M800 0 L0 450" stroke="hsl(0 84.2% 60.2% / 0.15)" stroke-width="1"/>
  <path d="M400 0 L400 450 M0 225 L800 225" stroke="hsl(0 84.2% 60.2% / 0.15)" stroke-width="1"/>
  
  <g transform="translate(100, 80)">
    <!-- CRM Box -->
    <g transform="rotate(-2)">
      <rect width="180" height="120" rx="8" fill="hsl(0 0% 100%)" stroke="hsl(0 84.2% 60.2%)" stroke-width="2"/>
      <text x="90" y="45" text-anchor="middle" font-family="system-ui" font-size="22" font-weight="600" fill="hsl(0 84.2% 50.2%)">CRM System</text>
      <text x="90" y="75" text-anchor="middle" font-family="system-ui" font-size="16" font-weight="500" fill="hsl(203 20% 46.9%)">Manual Updates</text>
      <text x="90" y="95" text-anchor="middle" font-family="system-ui" font-size="14" fill="hsl(203 20% 46.9%)">4+ Hours Daily</text>
      <circle cx="30" cy="30" r="12" fill="url(#warningGradient)"/>
      <text x="30" y="35" text-anchor="middle" font-family="system-ui" font-size="16" font-weight="600" fill="hsl(0 84.2% 50.2%)">!</text>
    </g>
    
    <!-- Email Box -->
    <g transform="translate(420, 0) rotate(2)">
      <rect width="180" height="120" rx="8" fill="hsl(0 0% 100%)" stroke="hsl(0 84.2% 60.2%)" stroke-width="2"/>
      <text x="90" y="45" text-anchor="middle" font-family="system-ui" font-size="22" font-weight="600" fill="hsl(0 84.2% 50.2%)">Email Platform</text>
      <text x="90" y="75" text-anchor="middle" font-family="system-ui" font-size="16" font-weight="500" fill="hsl(203 20% 46.9%)">No Integration</text>
      <text x="90" y="95" text-anchor="middle" font-family="system-ui" font-size="14" fill="hsl(203 20% 46.9%)">Manual Lists</text>
      <circle cx="30" cy="30" r="12" fill="url(#warningGradient)"/>
      <text x="30" y="35" text-anchor="middle" font-family="system-ui" font-size="16" font-weight="600" fill="hsl(0 84.2% 50.2%)">!</text>
    </g>
    
    <!-- Task Box -->
    <g transform="translate(210, 180) rotate(-1)">
      <rect width="180" height="120" rx="8" fill="hsl(0 0% 100%)" stroke="hsl(0 84.2% 60.2%)" stroke-width="2"/>
      <text x="90" y="45" text-anchor="middle" font-family="system-ui" font-size="22" font-weight="600" fill="hsl(0 84.2% 50.2%)">Task Management</text>
      <text x="90" y="75" text-anchor="middle" font-family="system-ui" font-size="16" font-weight="500" fill="hsl(203 20% 46.9%)">Multiple Tools</text>
      <text x="90" y="95" text-anchor="middle" font-family="system-ui" font-size="14" fill="hsl(203 20% 46.9%)">Data Scattered</text>
      <circle cx="30" cy="30" r="12" fill="url(#warningGradient)"/>
      <text x="30" y="35" text-anchor="middle" font-family="system-ui" font-size="16" font-weight="600" fill="hsl(0 84.2% 50.2%)">!</text>
    </g>
    
    <!-- Connection Lines showing chaos -->
    <g stroke="hsl(0 84.2% 60.2%)" stroke-width="2" fill="none">
      <path d="M180 60 C230 40, 280 80, 330 60 C380 40, 430 80, 420 60" stroke-dasharray="6,6" stroke-opacity="0.5"/>
      <path d="M300 60 C320 100, 280 140, 300 180" stroke-dasharray="6,6" stroke-opacity="0.5"/>
      <path d="M160 120 C200 150, 240 130, 280 160" stroke-dasharray="4,4" stroke-opacity="0.3"/>
      <path d="M440 120 C400 150, 360 130, 320 160" stroke-dasharray="4,4" stroke-opacity="0.3"/>
    </g>

    <!-- Small warning indicators -->
    <g fill="hsl(0 84.2% 60.2%)" opacity="0.3">
      <circle cx="250" cy="100" r="4"/>
      <circle cx="350" cy="120" r="4"/>
      <circle cx="280" cy="140" r="4"/>
    </g>
  </g>

  <!-- Additional Decorative Elements -->
  <g opacity="0.15" stroke="hsl(0 84.2% 60.2%)">
    <path d="M60 380 L120 380" stroke-width="2"/>
    <path d="M680 70 L740 70" stroke-width="2"/>
    <circle cx="90" cy="380" r="2" fill="hsl(0 84.2% 60.2%)"/>
    <circle cx="710" cy="70" r="2" fill="hsl(0 84.2% 60.2%)"/>
  </g>
</svg>
`)}`;

const lightAfterImage = `data:image/svg+xml,${encodeURIComponent(`
<svg xmlns="http://www.w3.org/2000/svg" width="800" height="450">
  <rect width="800" height="450" fill="hsl(40 33% 98%)"/>
  
  <g transform="translate(100, 60)">
    <!-- Main Container -->
    <rect width="600" height="330" rx="12" fill="hsl(0 0% 100%)" stroke="hsl(203 89% 53%)" stroke-width="2"/>
    <text x="300" y="45" text-anchor="middle" font-family="system-ui" font-size="28" font-weight="600" fill="hsl(203 89% 53%)">Unified System</text>
    
    <!-- Integration Boxes -->
    <g transform="translate(30, 70)">
      <!-- CRM -->
      <g>
        <rect width="160" height="110" rx="8" fill="hsl(203 89% 96%)" stroke="hsl(203 89% 53%)"/>
        <text x="80" y="40" text-anchor="middle" font-family="system-ui" font-size="22" font-weight="600" fill="hsl(203 89% 33%)">CRM</text>
        <text x="80" y="70" text-anchor="middle" font-family="system-ui" font-size="16" font-weight="500" fill="hsl(203 89% 33%)">Integrated</text>
        <circle cx="30" cy="30" r="12" fill="hsl(142.1 76.2% 36.3%)"/>
        <text x="30" y="35" text-anchor="middle" font-family="system-ui" font-size="16" font-weight="600" fill="hsl(0 0% 100%)">✓</text>
      </g>
      
      <!-- Email -->
      <g transform="translate(190, 0)">
        <rect width="160" height="110" rx="8" fill="hsl(203 89% 96%)" stroke="hsl(203 89% 53%)"/>
        <text x="80" y="40" text-anchor="middle" font-family="system-ui" font-size="22" font-weight="600" fill="hsl(203 89% 33%)">Email</text>
        <text x="80" y="70" text-anchor="middle" font-family="system-ui" font-size="16" font-weight="500" fill="hsl(203 89% 33%)">Automated</text>
        <circle cx="30" cy="30" r="12" fill="hsl(142.1 76.2% 36.3%)"/>
        <text x="30" y="35" text-anchor="middle" font-family="system-ui" font-size="16" font-weight="600" fill="hsl(0 0% 100%)">✓</text>
      </g>
      
      <!-- Tasks -->
      <g transform="translate(380, 0)">
        <rect width="160" height="110" rx="8" fill="hsl(203 89% 96%)" stroke="hsl(203 89% 53%)"/>
        <text x="80" y="40" text-anchor="middle" font-family="system-ui" font-size="22" font-weight="600" fill="hsl(203 89% 33%)">Tasks</text>
        <text x="80" y="70" text-anchor="middle" font-family="system-ui" font-size="16" font-weight="500" fill="hsl(203 89% 33%)">Streamlined</text>
        <circle cx="30" cy="30" r="12" fill="hsl(142.1 76.2% 36.3%)"/>
        <text x="30" y="35" text-anchor="middle" font-family="system-ui" font-size="16" font-weight="600" fill="hsl(0 0% 100%)">✓</text>
      </g>
    </g>
    
    <!-- Benefits Box -->
    <g transform="translate(30, 220)">
      <rect width="540" height="80" rx="8" fill="hsl(203 89% 96%)" stroke="hsl(203 89% 53%)"/>
      <text x="270" y="35" text-anchor="middle" font-family="system-ui" font-size="20" font-weight="600" fill="hsl(203 89% 33%)">Key Benefits</text>
      <text x="270" y="60" text-anchor="middle" font-family="system-ui" font-size="16" font-weight="500" fill="hsl(203 89% 33%)">
        Real-time Sync • Automation • Enhanced Security • Analytics
      </text>
    </g>
  </g>
</svg>
`)}`;

// ---- Dark Mode SVGs ---- //
const darkBeforeImage = `data:image/svg+xml,${encodeURIComponent(`
<svg xmlns="http://www.w3.org/2000/svg" width="800" height="450">
  <defs>
    <!-- Warning Gradient -->
    <linearGradient id="warningGradient" x1="0%" y1="0%" x2="100%" y2="100%">
      <stop offset="0%" stop-color="hsl(0, 62.8%, 40.6%)"/>
      <stop offset="100%" stop-color="hsl(0, 62.8%, 30.6%)"/>
    </linearGradient>
  </defs>

  <!-- Dark background -->
  <rect width="800" height="450" fill="hsl(222.2, 47%, 11%)"/>

  <!-- Subtle cross lines -->
  <path d="M0 0 L800 450 M800 0 L0 450" stroke="hsl(0, 62.8%, 30.6% / 0.1)" stroke-width="1"/>
  <path d="M400 0 L400 450 M0 225 L800 225" stroke="hsl(0, 62.8%, 30.6% / 0.1)" stroke-width="1"/>

  <g transform="translate(100, 80)">
    <!-- CRM Box -->
    <g transform="rotate(-2)">
      <rect width="180" height="120" rx="8" fill="hsl(222.2, 84%, 4.9%)" stroke="hsl(0, 62.8%, 30.6%)" stroke-width="2"/>
      <text x="90" y="45" text-anchor="middle" font-family="system-ui" font-size="22" font-weight="600" fill="hsl(210, 40%, 98%)">CRM System</text>
      <text x="90" y="75" text-anchor="middle" font-family="system-ui" font-size="16" font-weight="500" fill="hsl(203, 20%, 75%)">Manual Updates</text>
      <text x="90" y="95" text-anchor="middle" font-family="system-ui" font-size="14" fill="hsl(203, 20%, 75%)">4+ Hours Daily</text>
      <circle cx="30" cy="30" r="12" fill="url(#warningGradient)"/>
      <text x="30" y="35" text-anchor="middle" font-family="system-ui" font-size="16" font-weight="600" fill="hsl(210, 40%, 98%)">!</text>
    </g>
    
    <!-- Email Box -->
    <g transform="translate(420, 0) rotate(2)">
      <rect width="180" height="120" rx="8" fill="hsl(222.2, 84%, 4.9%)" stroke="hsl(0, 62.8%, 30.6%)" stroke-width="2"/>
      <text x="90" y="45" text-anchor="middle" font-family="system-ui" font-size="22" font-weight="600" fill="hsl(210, 40%, 98%)">Email Platform</text>
      <text x="90" y="75" text-anchor="middle" font-family="system-ui" font-size="16" font-weight="500" fill="hsl(203, 20%, 75%)">No Integration</text>
      <text x="90" y="95" text-anchor="middle" font-family="system-ui" font-size="14" fill="hsl(203, 20%, 75%)">Manual Lists</text>
      <circle cx="30" cy="30" r="12" fill="url(#warningGradient)"/>
      <text x="30" y="35" text-anchor="middle" font-family="system-ui" font-size="16" font-weight="600" fill="hsl(210, 40%, 98%)">!</text>
    </g>
    
    <!-- Task Box -->
    <g transform="translate(210, 180) rotate(-1)">
      <rect width="180" height="120" rx="8" fill="hsl(222.2, 84%, 4.9%)" stroke="hsl(0, 62.8%, 30.6%)" stroke-width="2"/>
      <text x="90" y="45" text-anchor="middle" font-family="system-ui" font-size="22" font-weight="600" fill="hsl(210, 40%, 98%)">Task Management</text>
      <text x="90" y="75" text-anchor="middle" font-family="system-ui" font-size="16" font-weight="500" fill="hsl(203, 20%, 75%)">Multiple Tools</text>
      <text x="90" y="95" text-anchor="middle" font-family="system-ui" font-size="14" fill="hsl(203, 20%, 75%)">Data Scattered</text>
      <circle cx="30" cy="30" r="12" fill="url(#warningGradient)"/>
      <text x="30" y="35" text-anchor="middle" font-family="system-ui" font-size="16" font-weight="600" fill="hsl(210, 40%, 98%)">!</text>
    </g>
    
    <!-- Connection Lines showing chaos -->
    <g stroke="hsl(0, 62.8%, 30.6%)" stroke-width="2" fill="none">
      <path d="M180 60 C230 40, 280 80, 330 60 C380 40, 430 80, 420 60" stroke-dasharray="6,6" stroke-opacity="0.5"/>
      <path d="M300 60 C320 100, 280 140, 300 180" stroke-dasharray="6,6" stroke-opacity="0.5"/>
      <path d="M160 120 C200 150, 240 130, 280 160" stroke-dasharray="4,4" stroke-opacity="0.3"/>
      <path d="M440 120 C400 150, 360 130, 320 160" stroke-dasharray="4,4" stroke-opacity="0.3"/>
    </g>

    <!-- Small warning indicators -->
    <g fill="hsl(0, 62.8%, 30.6%)" opacity="0.3">
      <circle cx="250" cy="100" r="4"/>
      <circle cx="350" cy="120" r="4"/>
      <circle cx="280" cy="140" r="4"/>
    </g>
  </g>

  <!-- Additional Decorative Elements -->
  <g opacity="0.15" stroke="hsl(0, 62.8%, 30.6%)">
    <path d="M60 380 L120 380" stroke-width="2"/>
    <path d="M680 70 L740 70" stroke-width="2"/>
    <circle cx="90" cy="380" r="2" fill="hsl(0, 62.8%, 30.6%)"/>
    <circle cx="710" cy="70" r="2" fill="hsl(0, 62.8%, 30.6%)"/>
  </g>
</svg>
`)}`;

const darkAfterImage = `data:image/svg+xml,${encodeURIComponent(`
<svg xmlns="http://www.w3.org/2000/svg" width="800" height="450">
  <!-- Dark background -->
  <rect width="800" height="450" fill="hsl(222.2, 47%, 11%)"/>

  <g transform="translate(100, 60)">
    <!-- Main Container -->
    <rect width="600" height="330" rx="12" fill="hsl(222.2, 84%, 4.9%)" stroke="hsl(203, 89%, 53%)" stroke-width="2"/>
    <text x="300" y="45" text-anchor="middle" font-family="system-ui" font-size="28" font-weight="600" fill="hsl(203, 89%, 53%)">Unified System</text>
    
    <!-- Integration Boxes -->
    <g transform="translate(30, 70)">
      <!-- CRM -->
      <g>
        <rect width="160" height="110" rx="8" fill="hsl(203, 50%, 20%)" stroke="hsl(203, 89%, 53%)"/>
        <text x="80" y="40" text-anchor="middle" font-family="system-ui" font-size="22" font-weight="600" fill="hsl(203, 89%, 96%)">CRM</text>
        <text x="80" y="70" text-anchor="middle" font-family="system-ui" font-size="16" font-weight="500" fill="hsl(203, 89%, 96%)">Integrated</text>
        <circle cx="30" cy="30" r="12" fill="hsl(203, 89%, 53%)"/>
        <text x="30" y="35" text-anchor="middle" font-family="system-ui" font-size="16" font-weight="600" fill="hsl(0, 0%, 100%)">✓</text>
      </g>
      
      <!-- Email -->
      <g transform="translate(190, 0)">
        <rect width="160" height="110" rx="8" fill="hsl(203, 50%, 20%)" stroke="hsl(203, 89%, 53%)"/>
        <text x="80" y="40" text-anchor="middle" font-family="system-ui" font-size="22" font-weight="600" fill="hsl(203, 89%, 96%)">Email</text>
        <text x="80" y="70" text-anchor="middle" font-family="system-ui" font-size="16" font-weight="500" fill="hsl(203, 89%, 96%)">Automated</text>
        <circle cx="30" cy="30" r="12" fill="hsl(203, 89%, 53%)"/>
        <text x="30" y="35" text-anchor="middle" font-family="system-ui" font-size="16" font-weight="600" fill="hsl(0, 0%, 100%)">✓</text>
      </g>
      
      <!-- Tasks -->
      <g transform="translate(380, 0)">
        <rect width="160" height="110" rx="8" fill="hsl(203, 50%, 20%)" stroke="hsl(203, 89%, 53%)"/>
        <text x="80" y="40" text-anchor="middle" font-family="system-ui" font-size="22" font-weight="600" fill="hsl(203, 89%, 96%)">Tasks</text>
        <text x="80" y="70" text-anchor="middle" font-family="system-ui" font-size="16" font-weight="500" fill="hsl(203, 89%, 96%)">Streamlined</text>
        <circle cx="30" cy="30" r="12" fill="hsl(203, 89%, 53%)"/>
        <text x="30" y="35" text-anchor="middle" font-family="system-ui" font-size="16" font-weight="600" fill="hsl(0, 0%, 100%)">✓</text>
      </g>
    </g>
    
    <!-- Benefits Box -->
    <g transform="translate(30, 220)">
      <rect width="540" height="80" rx="8" fill="hsl(203, 50%, 20%)" stroke="hsl(203, 89%, 53%)"/>
      <text x="270" y="35" text-anchor="middle" font-family="system-ui" font-size="20" font-weight="600" fill="hsl(203, 89%, 96%)">Key Benefits</text>
      <text x="270" y="60" text-anchor="middle" font-family="system-ui" font-size="16" font-weight="500" fill="hsl(203, 89%, 96%)">
        Real-time Sync • Automation • Enhanced Security • Analytics
      </text>
    </g>
  </g>
</svg>
`)}`;

// ---- Comparison Slider Component ---- //
interface ComparisonSliderProps {
  className?: string;
  beforeLabel?: string;
  afterLabel?: string;
}

export function ComparisonSlider({
  className,
  beforeLabel = "Current Stack",
  afterLabel = "Optimized Stack",
}: ComparisonSliderProps) {
  const [sliderPosition, setSliderPosition] = useState(50);
  const containerRef = useRef<HTMLDivElement>(null);
  const { isDark } = useTheme();

  // Use theme-appropriate images
  const currentBeforeImage = isDark ? darkBeforeImage : lightBeforeImage;
  const currentAfterImage = isDark ? darkAfterImage : lightAfterImage;

  // Handle mouse movement for slider position
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleMouseMove = (e: MouseEvent) => {
      const rect = container.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const position = (x / rect.width) * 100;
      setSliderPosition(Math.max(0, Math.min(100, position)));
    };

    const handleMouseLeave = () => {
      setSliderPosition(50); // Reset to center when mouse leaves
    };

    container.addEventListener("mousemove", handleMouseMove);
    container.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      container.removeEventListener("mousemove", handleMouseMove);
      container.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className={cn(
        "relative w-full rounded-lg overflow-hidden bg-muted/30 group/slider shadow-xl",
        className
      )}
    >
      {/* Subtle background gradient */}
      <div
        className="absolute inset-0 z-[1] bg-gradient-to-r from-primary/5 via-transparent to-accent/5"
        style={{
          backgroundSize: "200% 200%",
        }}
      />

      {/* Gradient Overlays */}
      <div className="absolute inset-0 bg-gradient-to-r from-background/10 via-transparent to-background/10 pointer-events-none z-10" />
      <div className="absolute inset-0 bg-gradient-to-b from-background/10 via-transparent to-background/10 pointer-events-none z-10" />
      
      {/* Slider */}
      <div className="aspect-[16/9] relative z-[2]">
        <ReactCompareImage
          leftImage={currentBeforeImage}
          rightImage={currentAfterImage}
          sliderPosition={sliderPosition}
          handle={
            <div className="relative w-0.5 h-full bg-white/80 backdrop-blur-sm">
              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-3 h-3 rounded-full bg-white shadow-md border border-primary/50">
                <div className="w-1.5 h-1.5 rounded-full bg-primary absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
              </div>
            </div>
          }
          sliderLineColor="transparent"
          hover={true}
        />
      </div>

      {/* Minimal Labels */}
      <div
        className={`absolute top-3 left-3 py-1 px-2 text-xs font-medium text-primary/80 
                   bg-white/80 rounded-md backdrop-blur-[2px]
                   ${sliderPosition > 15 ? "opacity-0" : "opacity-100"}`}
      >
        {beforeLabel}
      </div>
      <div
        className={`absolute top-3 right-3 py-1 px-2 text-xs font-medium text-primary/80 
                   bg-white/80 rounded-md backdrop-blur-[2px]
                   ${sliderPosition < 85 ? "opacity-0" : "opacity-100"}`}
      >
        {afterLabel}
      </div>

      {/* Subtle Corner Decorations */}
      <div className="absolute top-0 left-0 w-12 h-12 border-t border-l border-primary/10 rounded-tl-lg" />
      <div className="absolute top-0 right-0 w-12 h-12 border-t border-r border-primary/10 rounded-tr-lg" />
      <div className="absolute bottom-0 left-0 w-12 h-12 border-b border-l border-primary/10 rounded-bl-lg" />
      <div className="absolute bottom-0 right-0 w-12 h-12 border-b border-r border-primary/10 rounded-br-lg" />
    </div>
  );
}