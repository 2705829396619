import { useState } from "react";
import { Header } from "@/components/Header";
import { Button } from "@/components/ui/button";
import { useLocation } from "wouter";
import { ArrowRight, Play } from "lucide-react";
import { VideoPlayer } from "@/components/VideoPlayer";
import { Card } from "@/components/ui/card";

interface Service {
  id: number;
  title: string;
  description: string;
  features: string[];
  category: string;
  visual?: {
    type: 'video' | 'image' | 'placeholder';
    src?: string;
    title?: string;
    description?: string;
  };
}

const services: Service[] = [
  {
    id: 1,
    title: "Quote Automation System",
    description: "Streamline your quoting process with AI-powered automation, enabling faster, more accurate client responses.",
    features: [
      "Automated quote generation in under 2 minutes",
      "Website integration for seamless use",
      "Custom service templates tailored to your business",
      "Real-time quote tracking dashboard",
      "Detailed analytics to measure success",
      "Mobile-friendly client portal"
    ],
    category: "Automation",
    visual: {
      type: 'video',
      src: "/videos/AutoQuoteDemo.mp4",
      title: "AutoQuote Demo - Blazing Fast Quote Responses",
      description: "See how our AI-powered system instantly transforms customer quote requests into accurate, professional estimates."
    }
  },
  {
    id: 2,
    title: "Website Refresh & Revamp",
    description: "Transform your digital presence with our comprehensive website modernization services.",
    features: [
      "Modern design refresh",
      "Mobile responsiveness",
      "Lead capture optimization",
      "Professional branding",
      "SEO optimization",
      "Performance tuning"
    ],
    category: "Web Development",
    visual: {
      type: 'placeholder',
      title: "Website Transformation Preview",
      description: "Interactive preview of our website modernization process"
    }
  },
  {
    id: 3,
    title: "CRM Integration",
    description: "Streamline your customer relationships with powerful CRM solutions, ready for future proposal management features.",
    features: [
      "Popular CRM integrations",
      "Data migration",
      "Workflow automation",
      "Team training",
      "Custom reporting",
      "Future proposal management integration"
    ],
    category: "Business Solutions",
    visual: {
      type: 'placeholder',
      title: "CRM Integration Demo",
      description: "See how our CRM solutions can streamline your customer relationships"
    }
  },
  {
    id: 4,
    title: "Database Management",
    description: "Optimize your data infrastructure for better performance and scalability.",
    features: [
      "Setup & optimization",
      "Ongoing maintenance",
      "Performance monitoring",
      "Scalability planning",
      "Security hardening",
      "Backup solutions"
    ],
    category: "Infrastructure",
    visual: {
      type: 'placeholder',
      title: "Database Management Overview",
      description: "Learn how our database management services can improve your data infrastructure"
    }
  },
  {
    id: 5,
    title: "Tech-Aid Kit",
    description: "Your monthly technical support solution for ongoing maintenance and updates.",
    features: [
      "Regular updates",
      "Bug fixes",
      "Performance monitoring",
      "Priority support",
      "Security patches",
      "Monthly reports"
    ],
    category: "Support",
    visual: {
      type: 'placeholder',
      title: "Tech-Aid Kit Demo",
      description: "See how our Tech-Aid Kit can provide ongoing technical support for your business"
    }
  },
  {
    id: 6,
    title: "Custom Development",
    description: "Tailored software solutions designed for your specific business needs, with upcoming contract management capabilities.",
    features: [
      "Custom tools",
      "Rapid development",
      "Focused solutions",
      "Ongoing support",
      "Contract management ready",
      "Future-proof design"
    ],
    category: "Development",
    visual: {
      type: 'placeholder',
      title: "Custom Development Overview",
      description: "Learn how our custom development services can help your business thrive"
    }
  }
];

export default function ProductsPage() {
  const [, navigate] = useLocation();

  const renderVisual = (visual: Service['visual']) => {
    if (!visual) return null;

    switch (visual.type) {
      case 'video':
        return (
          <VideoPlayer
            src={visual.src!}
            title={visual.title!}
            description={visual.description!}
          />
        );
      case 'placeholder':
        return (
          <div className="relative h-full min-h-[300px] bg-card rounded-lg flex items-center justify-center group cursor-pointer overflow-hidden border border-border/50">
            <div className="absolute inset-0 bg-gradient-to-br from-primary/5 to-transparent" />
            <div className="absolute inset-0 bg-grid-primary/[0.02]" />
            <div className="relative z-10 text-center px-6">
              <div className="w-16 h-16 bg-primary/10 rounded-full flex items-center justify-center mx-auto mb-4 group-hover:bg-primary/20 transition-colors">
                <Play className="w-8 h-8 text-primary" />
              </div>
              <h4 className="text-xl font-semibold mb-3 text-gradient">{visual.title}</h4>
              <p className="text-sm text-muted-foreground">{visual.description}</p>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-background">
      <Header />
      <main className="py-16 sm:py-20">
        <div className="container mx-auto px-4 sm:px-8">
          <div className="text-center mb-16">
            <h1 className="text-4xl sm:text-5xl font-bold mb-4 text-gradient">Our Services</h1>
            <p className="text-lg sm:text-xl text-muted-foreground max-w-2xl mx-auto">
              Comprehensive solutions to modernize and grow your business
            </p>
          </div>

          {/* Staggered Services Grid */}
          <div className="space-y-24 mb-24">
            {services.map((service, index) => (
              <div 
                key={service.id}
                className={`grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-12 items-center max-w-7xl mx-auto`}
              >
                <div className={`space-y-6 ${index % 2 === 1 ? 'lg:order-2' : ''}`}>
                  <h2 className="text-3xl font-bold text-gradient">{service.title}</h2>
                  <p className="text-lg text-muted-foreground">
                    {service.description}
                  </p>
                  <div className="space-y-3">
                    {service.features.map((feature, idx) => (
                      <div key={idx} className="flex items-start gap-3">
                        <ArrowRight className="w-5 h-5 text-primary flex-shrink-0 mt-1" />
                        <span className="text-muted-foreground">{feature}</span>
                      </div>
                    ))}
                  </div>
                  <Button 
                    size="lg"
                    onClick={() => navigate('/book-call')}
                    className="btn-primary text-lg px-8"
                  >
                    Learn More
                    <ArrowRight className="ml-2 h-5 w-5" />
                  </Button>
                </div>
                <div className={`${index % 2 === 1 ? 'lg:order-1' : ''}`}>
                  {renderVisual(service.visual)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>

      {/* CTA Section */}
      <section className="py-16 sm:py-20 bg-gradient-to-b from-background via-primary/5 to-background relative overflow-hidden">
        <div className="absolute inset-0 bg-grid-primary/[0.02] -z-10" />
        <div 
          className="absolute inset-0 bg-gradient-to-b from-transparent via-primary/5 to-transparent -z-10"
          style={{
            maskImage: 'linear-gradient(to bottom, transparent, black, transparent)',
            WebkitMaskImage: 'linear-gradient(to bottom, transparent, black, transparent)',
          }}
        />
        <div className="container max-w-4xl mx-auto px-4 sm:px-8 text-center">
          <h2 className="text-3xl sm:text-4xl font-bold mb-4 text-gradient">Ready to Transform Your Business?</h2>
          <p className="text-lg sm:text-xl text-muted-foreground mb-8 max-w-2xl mx-auto">
            Book a free consultation to discuss your needs and explore solutions
          </p>
          <Button 
            size="lg" 
            onClick={() => navigate('/book-call')} 
            className="btn-primary text-lg px-8"
          >
            Book Free Consultation
            <ArrowRight className="ml-2 h-5 w-5" />
          </Button>
        </div>
      </section>
    </div>
  );
}