import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Header } from "@/components/Header";
import { useLocation } from "wouter";
import { 
  ClipboardCheck, 
  Search, 
  LineChart, 
  Zap, 
  ArrowRight, 
  FileSpreadsheet,
  CheckCircle
} from "lucide-react";
import { ServiceSelectionFlow } from "@/components/ServiceSelection/ServiceSelectionFlow";

export default function AuditProcess() {
  const [, navigate] = useLocation();

  return (
    <div className="min-h-screen bg-background">
      <Header />
      <main className="relative">
        {/* Hero Section */}
        <section className="py-16 sm:py-24 bg-gradient-to-b from-background via-primary/5 to-background relative overflow-hidden">
          <div className="absolute inset-0 bg-grid-primary/[0.02] -z-10" />
          <div 
            className="absolute inset-0 bg-gradient-to-b from-transparent via-primary/5 to-transparent -z-10"
            style={{
              maskImage: 'linear-gradient(to bottom, transparent, black, transparent)',
              WebkitMaskImage: 'linear-gradient(to bottom, transparent, black, transparent)',
            }}
          />
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto text-center">
              <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-6 tracking-tight">
                <span className="text-gradient">Tech Stack Audit</span> Process
              </h1>
              <p className="text-xl text-muted-foreground mb-8 leading-relaxed max-w-2xl mx-auto">
                Discover how our comprehensive audit process can transform your business operations and drive growth
              </p>
            </div>
          </div>
        </section>

        {/* Process Steps */}
        <section className="py-16 sm:py-20">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h2 className="text-3xl sm:text-4xl font-bold mb-4">How It Works</h2>
              <p className="text-lg text-muted-foreground max-w-2xl mx-auto">
                Our streamlined process ensures you get actionable insights quickly
              </p>
            </div>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-7xl mx-auto">
              <Card className="p-8 bg-card/80 backdrop-blur-sm shadow-lg hover:shadow-xl transition-all duration-300 border border-primary/10">
                <div className="w-14 h-14 rounded-full bg-primary/10 flex items-center justify-center mb-6">
                  <Search className="w-7 h-7 text-primary" />
                </div>
                <h3 className="text-xl font-semibold mb-3">Initial Assessment</h3>
                <p className="text-muted-foreground leading-relaxed">We analyze your current tech stack and business processes to identify areas for improvement.</p>
              </Card>
              
              <Card className="p-8 bg-card/80 backdrop-blur-sm shadow-lg hover:shadow-xl transition-all duration-300 border border-primary/10">
                <div className="w-14 h-14 rounded-full bg-primary/10 flex items-center justify-center mb-6">
                  <LineChart className="w-7 h-7 text-primary" />
                </div>
                <h3 className="text-xl font-semibold mb-3">Opportunity Analysis</h3>
                <p className="text-muted-foreground leading-relaxed">Our experts identify automation opportunities and efficiency gains specific to your business.</p>
              </Card>
              
              <Card className="p-8 bg-card/80 backdrop-blur-sm shadow-lg hover:shadow-xl transition-all duration-300 border border-primary/10">
                <div className="w-14 h-14 rounded-full bg-primary/10 flex items-center justify-center mb-6">
                  <FileSpreadsheet className="w-7 h-7 text-primary" />
                </div>
                <h3 className="text-xl font-semibold mb-3">Detailed Report</h3>
                <p className="text-muted-foreground leading-relaxed">Receive a comprehensive report with actionable recommendations and implementation steps.</p>
              </Card>
              
              <Card className="p-8 bg-card/80 backdrop-blur-sm shadow-lg hover:shadow-xl transition-all duration-300 border border-primary/10">
                <div className="w-14 h-14 rounded-full bg-primary/10 flex items-center justify-center mb-6">
                  <Zap className="w-7 h-7 text-primary" />
                </div>
                <h3 className="text-xl font-semibold mb-3">Implementation Plan</h3>
                <p className="text-muted-foreground leading-relaxed">Get a clear roadmap for implementing improvements with estimated timelines and ROI.</p>
              </Card>
            </div>
          </div>
        </section>

        {/* Benefits Section */}
        <section className="py-16 sm:py-20">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto">
              <h2 className="text-3xl sm:text-4xl font-bold text-center mb-16">Why Get an Audit?</h2>
              <div className="space-y-8">
                <div className="flex items-start gap-6 group">
                  <div className="w-12 h-12 rounded-full bg-primary/10 flex items-center justify-center flex-shrink-0 group-hover:bg-primary/20 transition-colors duration-300">
                    <CheckCircle className="w-6 h-6 text-primary" />
                  </div>
                  <div>
                    <h3 className="text-2xl font-semibold mb-3">Identify Cost Savings</h3>
                    <p className="text-lg text-muted-foreground leading-relaxed">Discover opportunities to reduce operational costs through improved technology utilization and process automation.</p>
                  </div>
                </div>
                
                <div className="flex items-start gap-6 group">
                  <div className="w-12 h-12 rounded-full bg-primary/10 flex items-center justify-center flex-shrink-0 group-hover:bg-primary/20 transition-colors duration-300">
                    <CheckCircle className="w-6 h-6 text-primary" />
                  </div>
                  <div>
                    <h3 className="text-2xl font-semibold mb-3">Increase Efficiency</h3>
                    <p className="text-lg text-muted-foreground leading-relaxed">Streamline your operations with automated workflows and improved process management, saving time and reducing errors.</p>
                  </div>
                </div>
                
                <div className="flex items-start gap-6 group">
                  <div className="w-12 h-12 rounded-full bg-primary/10 flex items-center justify-center flex-shrink-0 group-hover:bg-primary/20 transition-colors duration-300">
                    <CheckCircle className="w-6 h-6 text-primary" />
                  </div>
                  <div>
                    <h3 className="text-2xl font-semibold mb-3">Competitive Advantage</h3>
                    <p className="text-lg text-muted-foreground leading-relaxed">Stay ahead of your competition with modern, efficient business processes that scale with your growth.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Contact Form Section */}
        <section className="py-16 sm:py-20 bg-gradient-to-b from-background via-secondary/20 to-background relative overflow-hidden">
          <div className="absolute inset-0 bg-grid-primary/[0.02] -z-10" />
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto">
              <div className="text-center mb-16">
                <h2 className="text-3xl sm:text-4xl font-bold mb-4">Start Your Free Tech Audit</h2>
                <p className="text-lg sm:text-xl text-muted-foreground max-w-2xl mx-auto">
                  Fill out the form below to begin your journey to improved efficiency
                </p>
              </div>
              <ServiceSelectionFlow />
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
