import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { useLocation } from "wouter";

export default function ThankYou() {
  const [, setLocation] = useLocation();

  return (
    <div className="container max-w-4xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <Card className="p-6 sm:p-8 md:p-12">
        <div className="text-center space-y-6">
          <div className="space-y-2">
            <h1 className="text-2xl sm:text-3xl font-bold">Thank You for Reaching Out!</h1>
            <p className="text-muted-foreground">
              We've received your request and will be in touch within one business day.
            </p>
          </div>

          <div className="max-w-md mx-auto bg-muted/30 rounded-lg p-4">
            <h2 className="font-medium mb-2">What happens next?</h2>
            <ul className="text-sm text-muted-foreground text-left space-y-2">
              <li>✓ Our team will review your submission</li>
              <li>✓ We'll prepare relevant materials based on your needs</li>
              <li>✓ You'll receive a personalized response within 24 hours</li>
            </ul>
          </div>

          <div className="pt-4">
            <h2 className="font-medium mb-3">While you wait...</h2>
            <div className="flex flex-col sm:flex-row gap-3 justify-center">
              <Button
                variant="default"
                onClick={() => setLocation("/products")}
                className="w-full sm:w-auto"
              >
                Explore Our Services
              </Button>
              <Button
                variant="outline"
                onClick={() => setLocation("/")}
                className="w-full sm:w-auto"
              >
                Return Home
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
